import React, { memo } from 'react'
import { ReactComponent as Logo } from '@assets/Kantata-K-Orange-Box.svg'
import classes from './index.module.scss'
import { List, ListItem, Typography } from '@mui/material'
import { Blueprint } from '@interfaces'
import { useNavigate } from 'react-router-dom'
import { useStore } from '@hooks'
import { shallow } from 'zustand/shallow'
import routes from '@routes'
import configs from '@configs'

interface IProps {
  blueprints?: Blueprint[]
}
const Navbar = ({ blueprints }: IProps) => {
  const {
    layouts: { NavBar: root },
    configs: { home },
  } = configs
  const navigate = useNavigate()
  const [resetBlueprint, setNotFound, setCurrentCategoryTab] = useStore(
    (state) => [state.resetBlueprint, state.setNotFound, state.setCurrentCategoryTab],
    shallow
  )
  const handleClick = (e: React.MouseEvent) => {
    const { id } = e.currentTarget
    setNotFound(false)
    setCurrentCategoryTab(home)
    navigate(routes.blueprint.navigate(id))
  }
  const handleNewBlueprint = () => {
    resetBlueprint()
    navigate(routes.blueprint.newBlueprint.navigate())
  }
  return (
    <div className={classes['wrapper-nav']}>
      <div onClick={() => navigate(routes.home.path)} className={classes['logo-wrapper']}>
        <Logo />
      </div>
      <div className={classes['list-wrapper']}>
        <Typography
          variant="h4"
          onClick={handleNewBlueprint}
        >{`+ \t ${root.newBlueprint}`}</Typography>
        <Typography variant="h4">{root.blueprints}</Typography>
        <List>
          {blueprints &&
            blueprints.map(({ id, name }) => (
              <ListItem id={`${id}`} button key={id} onClick={handleClick}>
                {name}
              </ListItem>
            ))}
        </List>
      </div>
    </div>
  )
}

export default memo(Navbar)
