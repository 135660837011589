import React, { useRef } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useStore } from '@hooks'
import { Box } from '@mui/material'
import { TOKEN_URI } from '@uris'
import configs from '@configs'
import { EnumHTTPActions } from '@interfaces'
import routes from '@routes'

const Call = () => {
  const {
    configs: { preAuth: PRE_AUTH },
  } = configs
  const tokenRef = useRef<HTMLParagraphElement>(null)
  const navigate = useNavigate()
  const updateAuthToken = useStore((state) => state.setAuthToken)
  var storage = window.localStorage
  var preauthToken = storage.getItem(PRE_AUTH)
  var xhr = new XMLHttpRequest()
  xhr.open(EnumHTTPActions.POST, TOKEN_URI())
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.onreadystatechange = function () {
    const DONE = 4
    const OK = 201
    const NOT_AUTHORIZED_STATUS_CODE = 401
    if (xhr.readyState === DONE) {
      if (xhr.status === OK) {
        var token = JSON.parse(xhr.responseText).access_token

        updateAuthToken(token)

        if (tokenRef.current) {
          tokenRef.current.textContent = token
        }
      } else {
        /* eslint-disable */
        console.log('Error: ' + xhr.status)
        /* eslint-enable */
      }
    } else if (xhr.status === NOT_AUTHORIZED_STATUS_CODE) {
      storage.removeItem(PRE_AUTH)
      navigate(routes.login.navigate())
    }
  }

  xhr.send(JSON.stringify({ preauth_token: preauthToken }))
  return (
    <>
      <Box className="login-page-box">
        <p ref={tokenRef}></p>
      </Box>
      <Navigate to="/" />
    </>
  )
}

export default Call
