import React, { Fragment, useEffect, useMemo } from 'react'
import Home from './HomeTab'
import DataSet from './DataSet'
import { Tabs, Title } from '@components'
import { useParams, useLocation } from 'react-router-dom'
import './index.scss'
import { useQuery } from 'react-query'
import { GET_BLUEPRINT_URI } from '@uris'
import { CircularProgress, Box } from '@mui/material'
import { shallow } from 'zustand/shallow'
import { useConsumer, useStore } from '@hooks'
import {
  EnumValidateStates,
  BlueprintCategories,
  DataSetTypes,
  NavigatorState,
  CategoryDataSets,
} from '@interfaces'
import { DEV_MODE } from '@utils'
import configs from '@configs'

type IParams = {
  id?: string
}
const Content = () => {
  const {
    pages: {
      Blueprint: { home, newBlueprint },
    },
    configs: {
      colors: { GRAY_04 },
    },
  } = configs
  const { id } = useParams<IParams>()
  const location = useLocation()
  const navigatorState = location.state as NavigatorState
  const { message, disconnectConsumer } = useConsumer(id)
  /* eslint-disable */
  if (DEV_MODE) {
    console.log('Message', message)
  }
  /* eslint-enable */
  const [
    blueprint,
    currentCategoryTab,
    resetBlueprint,
    getBlueprint,
    setBlueprint,
    currentSubCategoryTab,
    setCurrentCategoryTab,
    setCurrentSubCategoryTab,
    blueprintCategories,
  ] = useStore(
    (state) => [
      state.blueprint,
      state.tabs.currentCategoryTab,
      state.resetBlueprint,
      state.getBlueprint,
      state.setBlueprint,
      state.tabs.subCurrentTab,
      state.setCurrentCategoryTab,
      state.setCurrentSubTab,
      state.tabs.blueprintCategories,
    ],
    shallow
  )
  const { data, isLoading, refetch } = useQuery(
    [`${GET_BLUEPRINT_URI(id as string)}`],
    () => getBlueprint(id as string, navigatorState?.persistTabs),
    {
      enabled: Boolean(id),
    }
  )
  /* This blocks quick request-states EG: clicking on a blueprint and fast clicking on new_blueprint; */
  if (!id && blueprint.Home.id) {
    resetBlueprint()
  }
  /*                                                                                                    */
  useEffect(() => {
    if (data && id !== blueprint.Home.id) setBlueprint(data)
    return () => {
      if (disconnectConsumer) disconnectConsumer()
    }
  }, [id, disconnectConsumer])

  useEffect(() => {
    if (message?.processed_at || message?.validated_at) refetch()
  }, [message?.processed_at, message?.validated_at])

  const datasets = useMemo(() => {
    return Object.keys(blueprint[currentCategoryTab] || {})
  }, [blueprint, currentCategoryTab])
  const isHomeTabActive = currentCategoryTab === home
  const dataSet =
    currentSubCategoryTab &&
    (blueprint[currentCategoryTab] as CategoryDataSets)[currentSubCategoryTab]

  return (
    <main>
      {isLoading || (id && !message) ? (
        <CircularProgress />
      ) : (
        <Fragment>
          <section>
            <Title header={blueprint.Home.name || newBlueprint} variant="h5" gutterBottom noWrap />
          </section>
          <section>
            <Tabs
              options={blueprintCategories}
              activeTab={currentCategoryTab}
              optionsStates={message?.categories}
              setActiveTab={(category) => setCurrentCategoryTab(category as BlueprintCategories)}
            />
          </section>
          {!isHomeTabActive && currentCategoryTab && currentSubCategoryTab ? (
            <Box
              sx={{
                width: '100%',
                height: (theme) => theme.spacing(6),
                minWidth: '60em',
                backgroundColor: GRAY_04,
              }}
            >
              <section>
                <Tabs
                  options={datasets}
                  activeTab={currentSubCategoryTab}
                  setActiveTab={(subTab) => setCurrentSubCategoryTab(subTab as DataSetTypes)}
                  optionsStates={message?.data_set_statuses}
                  defaultStyle={false}
                />
              </section>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  height: (theme) => theme.spacing(6),
                  minWidth: '60em',
                  backgroundColor: GRAY_04,
                }}
              />
              <section>
                <Home
                  labels={blueprint}
                  id={id}
                  onlineStatus={message?.status || EnumValidateStates.CREATED}
                  progressStatus={message && message.percent_complete}
                  processedTimeStamp={message?.processed_at}
                  validatedTimeStamp={message?.validated_at}
                  validationStartedAtTimeStamp={data?.validation_started_at}
                  processingStartedAtTimeStamp={data?.processing_started_at}
                  updatedAt={message?.updated_at}
                  createdBlueprintTabs={data?.categories}
                  nextStep={message?.next_step}
                  dataSetsAmount={data?.data_sets_count}
                  creator={data?.user_name}
                  createdAt={data?.created_at}
                />
              </section>
            </>
          )}

          {currentCategoryTab !== home && dataSet && (
            <section>
              <DataSet
                dataSet={dataSet}
                onlineStatus={
                  (message && message.data_set_statuses[currentSubCategoryTab]) ||
                  EnumValidateStates.CREATED
                }
                progressStatus={
                  message && message.data_set_percentage_completes[currentSubCategoryTab]
                }
              />
            </section>
          )}
        </Fragment>
      )}
    </main>
  )
}

export default Content
