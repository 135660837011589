import { GetState } from 'zustand'
import { NamedSet } from 'zustand/middleware'
import { GlobalState } from '../'
import {
  GET_DATA_SET_RECORDS,
  EXPORT_CSV,
  GET_CSV_TEMPLATE,
  UPLOAD_CSV_URI,
  GET_MAVENLINK_OBJECTS,
} from '@uris'
import {
  generateHeaders,
  validateResponse,
  downloadBlob,
  generateHeadersWithOnlyAuth,
} from '@utils'
import {
  RecordsResponse,
  RecordTypes,
  DataSetUploadCSVRequestParams,
  EnumHTTPActions,
  EnumResponseTypes,
  MavenLinkObjectsResponse,
} from '@interfaces'
import configs from '@configs'

const {
  core: { template: TEMPLATE },
} = configs
export interface DatasetServices {
  exportCSV: (dataSetId: number, errors?: boolean) => Promise<void>
  getRecords: (id: number | string, page: number) => Promise<RecordsResponse>
  getTemplate: (type: RecordTypes) => Promise<void>
  uploadCSV: (args: DataSetUploadCSVRequestParams) => Promise<number>
  getMavenLinkObjects: () => Promise<MavenLinkObjectsResponse>
}

const createSlice = (set: NamedSet<GlobalState>, get: GetState<GlobalState>): DatasetServices => ({
  exportCSV: async (dataSetId, errors) => {
    const res = await fetch(EXPORT_CSV(dataSetId, errors), {
      method: EnumHTTPActions.GET,
      headers: generateHeaders(get().token),
    })
    try {
      const csv = (await validateResponse(res, set, EnumResponseTypes.BLOB)) as Blob
      const currentBlueprint = get().blueprint
      const datasetDisplayName = get().tabs.subCurrentTab
      const fileName = `${currentBlueprint.Home.name} - ${datasetDisplayName}`
      downloadBlob(csv, fileName)
      return
    } catch (e) {
      throw new Error(e as string)
    }
  },
  getRecords: async (id, page) => {
    const res = await fetch(GET_DATA_SET_RECORDS(page, id), {
      method: EnumHTTPActions.GET,
      headers: generateHeaders(get().token),
    })
    try {
      const records = (await validateResponse(res, set)) as RecordsResponse
      return records
    } catch (e) {
      throw new Error(e as string)
    }
  },
  getMavenLinkObjects: async () => {
    const res = await fetch(GET_MAVENLINK_OBJECTS(), {
      method: EnumHTTPActions.GET,
      headers: generateHeaders(get().token),
    })
    try {
      const json = (await validateResponse(res, set)) as MavenLinkObjectsResponse
      return json
    } catch (e) {
      throw new Error(e as string)
    }
  },
  getTemplate: async (type) => {
    const res = await fetch(GET_CSV_TEMPLATE(type), {
      method: EnumHTTPActions.GET,
      headers: generateHeaders(get().token),
    })
    try {
      const csv = await res.blob()
      const displayName = get().tabs.subCurrentTab
      const filename = `${displayName} ${TEMPLATE}`
      downloadBlob(csv, filename)
      return
    } catch (e) {
      throw new Error(e as string)
    }
  },
  uploadCSV: async ({ file, id }) => {
    const form = new FormData()
    form.append('data_set[file]', file)
    const res = await fetch(UPLOAD_CSV_URI(id), {
      method: EnumHTTPActions.POST,
      body: form,
      headers: generateHeadersWithOnlyAuth(get().token),
    })
    try {
      const status = await validateResponse(res, set, EnumResponseTypes.STATUS)
      return status
    } catch (e) {
      throw new Error(e as string)
    }
  },
})

export default createSlice
