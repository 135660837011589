import { GetState } from 'zustand'
import { NamedSet } from 'zustand/middleware'
import { GlobalState } from '..'
import { DataSetTypes, BlueprintCategories } from '@interfaces'
import configs from '@configs'

const {
  configs: { home: HOME },
} = configs
export interface TabsServices {
  setCurrentSubTab: (option: DataSetTypes) => void
  setCurrentCategoryTab: (option: BlueprintCategories) => void
}

const createSlice = (set: NamedSet<GlobalState>, get: GetState<GlobalState>): TabsServices => ({
  setCurrentCategoryTab: (option) => {
    if (option !== HOME) {
      const dataSet = get().blueprint[option]
      const subCurrentTab = dataSet
        ? (Object.keys(dataSet)[0] as DataSetTypes)
        : get().tabs.subCurrentTab
      set(
        (state) => ({
          ...state,
          tabs: { ...get().tabs, currentCategoryTab: option, subCurrentTab },
        }),
        false,
        'setCurrentCategoryTab'
      )
    } else {
      set(
        (state) => ({
          ...state,
          tabs: { ...get().tabs, currentCategoryTab: HOME, subCurrentTab: null },
        }),
        false,
        'setCurrentCategoryTab'
      )
    }
  },
  setCurrentSubTab: (option) =>
    set(
      (state) => ({ ...state, tabs: { ...get().tabs, subCurrentTab: option } }),
      false,
      'setCurrentSubTab'
    ),
})

export default createSlice
