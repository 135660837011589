import { MavenLinkRecordsType, RecordTypes, BlueprintCategories } from '.'
export type CategoryDataSets = {
  [t in DataSetTypes]: IDataSet
}

export interface DataSetUploadCSVRequestParams {
  id: number
  file: File
}
export interface IDataSet extends MavenLinkRecordsType {
  id: number
  status: MavenlinkDataSetStates
  blueprint_id: number
  mavenlink_record_type: RecordTypes
  display_name: Exclude<DataSetTypes, 'Home'>
  fields?: string[]
  url: string
  records_url: string
  updated_at: string
  categories: BlueprintCategories[]
}
export type UploadStates = 'created' | 'uploading' | 'uploaded'
export type ValidateStates = 'created' | 'validating' | 'validated' | 'validated_with_errors'
export type KrushStates =
  | 'created'
  | 'processing'
  | 'processed'
  | 'processed_with_errors'
  | 'canceled'
export enum EnumUploadStates {
  CREATED = 'created',
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
}

export enum EnumValidateStates {
  CREATED = 'created',
  UPLOADING = 'validating',
  UPLOADED = 'validated',
  ERROR = 'validated_with_errors',
}

export enum EnumKrushStates {
  CREATED = 'created',
  UPLOADING = 'processing',
  UPLOADED = 'processed',
  ERROR = 'processed_with_errors',
  CANCELLED = 'canceled',
}
export type DataSetTypes =
  | 'Assignments'
  | 'Cost Rates'
  | 'Expenses'
  | 'Project Allocations'
  | 'Project Custom Field Values'
  | 'Project Participations'
  | 'Project Resource Custom Field Values'
  | 'Project Resources'
  | 'Projects'
  | 'Task Custom Field Values'
  | 'Task Followers'
  | 'Tasks'
  | 'Time Adjustments'
  | 'Time Entries'
  | 'Time Off Entries'
  | 'User Custom Field Values'
export type MavenlinkDataSetStates = UploadStates | ValidateStates | KrushStates
export type SetStatus = {
  [key in DataSetTypes]?: MavenlinkDataSetStates
}
export type DataSetPercentage = {
  [key in keyof SetStatus]: number
}
