import { GlobalState } from '..'
import { NamedSet } from 'zustand/middleware'
import { GetState } from 'zustand'
import createServiceAccounts, { AccountServices } from './services'

const createSlice = (set: NamedSet<GlobalState>, get: GetState<GlobalState>) => ({
  ...createServiceAccounts(set, get),
})

export default createSlice
export type { AccountServices }
