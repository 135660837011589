import { useState, useCallback, useMemo } from 'react'
import { createConsumer, Subscription, Consumer } from '@rails/actioncable'
import { WEB_SOCKET_URL } from '@uris'
import { IMessagePayload } from '@interfaces'
import configs from '@configs'

const {
  configs: { auth: AUTH },
} = configs
const BLUEPRINT_CHANNEL = 'BlueprintChannel'

var authToken = localStorage.getItem(AUTH) as string
var consumer = createConsumer(WEB_SOCKET_URL(authToken))

const reinitializeConsumer = () => {
  authToken = localStorage.getItem(AUTH) as string
  consumer = createConsumer(WEB_SOCKET_URL(authToken))
}

const subscription = (subscriptions: Subscription<Consumer>[], blueprintChannel?: string) => {
  const result = subscriptions.filter((sub) => sub.identifier === blueprintChannel)
  return result.pop()
}

const useConsumer = (blueprintId?: string) => {
  const [message, setMessage] = useState<IMessagePayload | null>(null)
  if (!blueprintId && message) setMessage(null)
  const blueprintChannel = { channel: BLUEPRINT_CHANNEL, blueprint_id: blueprintId }
  const parsedChannel = useMemo(() => JSON.stringify(blueprintChannel), [blueprintId])
  const disconnectConsumer = useCallback(() => {
    const subscriptionFound = subscription(consumer.subscriptions.subscriptions, parsedChannel)
    subscriptionFound?.unsubscribe()
  }, [blueprintId])
  const connectConsumer = () => {
    consumer.connect()
  }

  if (!authToken) {
    reinitializeConsumer()
  }
  if (blueprintId) {
    const subscriptionFound = subscription(consumer.subscriptions.subscriptions, parsedChannel)
    if (blueprintId && !subscriptionFound) {
      consumer.subscriptions.create(blueprintChannel, {
        connect() {
          connectConsumer()
        },
        disconnected() {
          disconnectConsumer()
        },
        received(data: IMessagePayload) {
          setMessage(data)
        },
      })
    }
  }
  return {
    message,
    disconnectConsumer,
  }
}

export default useConsumer
