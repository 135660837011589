import React, { useMemo } from 'react'
import { MainButton, Text, Title, Dropdown, DatePicker } from '@components'
import { useNavigate } from 'react-router-dom'
import { useStore } from '@hooks'
import { useMutation } from 'react-query'
import { useAlert } from 'react-alert'
import { useFormik } from 'formik'
import { Box } from '@mui/material'
import { Accounts, EnumAccounts } from '@interfaces'
import { generateUTCDate, addDaysToDate, removeErrorPreffix } from '@utils'
import routes from '@routes'
import configs from '@configs'

function AdminContentNew() {
  const {
    configs: { validationSchemas },
    core: { defaultMinDays, trialMaxDays, migrationMaxDays },
    pages: {
      Admins: { New: root },
    },
  } = configs
  const newAccountAlert = useAlert()
  const newAccount = useStore((state) => state.newAccount)
  const navigate = useNavigate()
  const handleCancel = () => {
    navigate(routes.admins.navigate())
  }

  const { mutate: createAccount } = useMutation(newAccount, {
    onSuccess: () => {
      navigate(routes.admins.navigate())
    },
    onError: (error: Error) => {
      newAccountAlert.error(removeErrorPreffix(error.message.replace(',', ', ')))
    },
  })
  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      type: EnumAccounts.CLIENT,
      userId: '',
      userName: '',
      expiresAt: addDaysToDate(defaultMinDays),
    },
    onSubmit: (values) => {
      createAccount({
        id: values.id,
        name: values.name,
        type: values.type.toLowerCase() as Accounts,
        userId: values.userId,
        userName: values.userName,
        expiresAt:
          (isTrial || isMigration) && values.expiresAt
            ? generateUTCDate(values.expiresAt)
            : undefined,
      })
    },
    validationSchema: validationSchemas.admin.new,
  })

  const parsedValue = useMemo(() => {
    if (formik.values.type && !root.accountTypesArray.includes(formik.values.type)) {
      const parsedAccountType =
        formik.values.type.charAt(0).toUpperCase() + formik.values.type.slice(1)
      return parsedAccountType
    } else {
      return formik.values.type
    }
  }, [formik.values.type])

  const defaultDate = useMemo(() => {
    return addDaysToDate(defaultMinDays)
  }, [])

  const [trialsMaxDaysTimeStamp, defaultMinDaysTimeStamp, migrationMaxDaysTimeStamp] =
    useMemo((): number[] => {
      return [
        addDaysToDate(trialMaxDays).getTime(),
        defaultDate.getTime(),
        addDaysToDate(migrationMaxDays).getTime(),
      ]
    }, [defaultDate])

  const accountType = parsedValue.toLowerCase()
  const isTrial = accountType === EnumAccounts.TRIAL
  const isMigration = accountType === EnumAccounts.MIGRATION

  return (
    <main>
      <section>
        <Title header={root.provisionAccountTitle} variant="h5" gutterBottom />
        <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <Text
            name="id"
            value={formik.values.id}
            disable={false}
            label={(formik.touched.id && formik.errors.id) || root.accountIdTextBoxTitle}
            outlined
            gutterBottom
            error={formik.touched.id && Boolean(formik.errors.id)}
            onChange={formik.handleChange}
          />
          <Text
            name="name"
            value={formik.values.name}
            disable={false}
            outlined
            gutterBottom
            error={formik.touched.name && Boolean(formik.errors.name)}
            label={(formik.touched.name && formik.errors.name) || root.accountNameTextBoxTitle}
            onChange={formik.handleChange}
          />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box sx={{ marginRight: 1 }}>
              <Dropdown
                name="type"
                values={root.accountTypesArray}
                value={parsedValue || root.accountTypesArray[0]}
                label={root.accountTypeTextBoxTitle}
                onChange={formik.handleChange}
              />
            </Box>
            {(isMigration || isTrial) && (
              <Box display="flex" justifyContent="flex-end">
                <DatePicker
                  value={formik.values.expiresAt}
                  onChange={(date) => formik.setFieldValue('expiresAt', date)}
                  minDate={defaultMinDaysTimeStamp}
                  maxDate={isTrial ? trialsMaxDaysTimeStamp : migrationMaxDaysTimeStamp}
                />
              </Box>
            )}
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <Text
              name="userId"
              value={formik.values.userId}
              label={(formik.touched.userId && formik.errors.userId) || root.userIdTextBoxTitle}
              outlined
              gutterBottom
              error={formik.touched.userId && Boolean(formik.errors.userId)}
              onChange={formik.handleChange}
            />
          </Box>

          <Text
            name="userName"
            value={formik.values.userName}
            disable={false}
            label={(formik.touched.userName && formik.errors.userName) || root.userNameTextBoxTitle}
            outlined
            gutterBottom
            error={formik.touched.userName && Boolean(formik.errors.userName)}
            onChange={formik.handleChange}
          />
          <Box mt={3}>
            <MainButton
              title={root.provisionAccountButtonText}
              className="top-text"
              type="submit"
              color="primary"
            />
            <MainButton
              style={{ marginLeft: '1em' }}
              title={root.cancelButtonText}
              type="button"
              color="inherit"
              onClick={handleCancel}
            />
          </Box>
        </form>
      </section>
    </main>
  )
}
export default AdminContentNew
