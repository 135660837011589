import { FormikProps } from 'formik'
import { useEffect } from 'react'

/* eslint-disable  @typescript-eslint/no-explicit-any */
const useFormikScrollError = (formik: FormikProps<any>) => {
  useEffect(() => {
    if (formik.isValid) return
    const firstErrorFieldName = Object.keys(formik.errors)[0]
    const nodeFieldError: Element | null = document.querySelector(
      `input[name='${firstErrorFieldName}']`
    )

    if (!nodeFieldError) return
    nodeFieldError.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }, [formik.submitCount, formik.isValid, formik.errors])
}
export default useFormikScrollError
