import { BlueprintSort, Pagination, RecordTypes } from '@interfaces'
const DEFAULT_PAGE_AMOUNT = 5
const { REACT_APP_BACKEND_HOST } = process.env
export const BASE_URL = `https://${REACT_APP_BACKEND_HOST}`
export const WEB_SOCKET_URL = (token: string) =>
  `wss://${REACT_APP_BACKEND_HOST}/cable?bearer_token=${token}`
export const GET_ACCOUNT_URI = (id?: string | number) =>
  id ? `${BASE_URL}/v1/admin/accounts/${id}` : `${BASE_URL}/v1/account`
export const GET_ACCOUNTS_URI = (page?: number, matching?: string) => {
  let url = page
    ? `${BASE_URL}/v1/admin/accounts?per_page=10&page=${page}`
    : `${BASE_URL}/v1/admin/accounts`
  if (matching) {
    url = page ? url + `&matching=${matching}` : url + `?matching=${matching}`
  }
  return url
}
export const GET_ACCOUNT_DISABLE_URI = (id: string | number) =>
  `${BASE_URL}/v1/admin/accounts/${id}/disable`
export const GET_ACCOUNT_ENABLE_URI = (id: string | number) =>
  `${BASE_URL}/v1/admin/accounts/${id}/enable`
export const GET_BLUEPRINTS_URI = (paginatedArgs?: Pagination) => {
  const { page = 1, amount = DEFAULT_PAGE_AMOUNT, sort, matching } = paginatedArgs || {}
  let blueprinstUri = `${BASE_URL}/v1/blueprints?per_page=${amount}&page=${page}`

  if (matching) {
    blueprinstUri += `&matching=${matching}`
  }
  if (sort) {
    blueprinstUri += Object.keys(sort)
      .map((header) => `&column=${header}&direction=${sort[header as BlueprintSort]}`)
      .join('')
  }

  return blueprinstUri
}
export const GET_BLUEPRINT_URI = (id: string | number) => `${BASE_URL}/v1/blueprints/${id}`
export const GET_DATA_SET_RECORDS = (page: number, id?: number | string) =>
  `${BASE_URL}/v1/data_sets/${id}/records?page=${page}&per_page=10`
export const GET_EMAIL_URI = () => `${BASE_URL}/v1/emails/toggle`
export const GET_MAVENLINK_OBJECTS = () => `${BASE_URL}/v1/mavenlink_objects`
export const UPLOAD_CSV_URI = (id: number) => `${BASE_URL}/v1/data_sets/${id}/upload`
export const VALIDATE_BLUEPRINT_URI = (id: string, processAfter: boolean = false) =>
  `${BASE_URL}/v1/blueprints/${id}/validate?process_after=${processAfter}`
export const PROCESS_BLUEPRINT_URI = (id: string) => `${BASE_URL}/v1/blueprints/${id}/kickoff`
export const RESET_DATASET_URI = (id: number) => `${BASE_URL}/v1/data_sets/${id}/reset`
export const GET_CSV_TEMPLATE = (type: RecordTypes) =>
  `${BASE_URL}/v1/mavenlink_objects/templates?object_type=${type}`
export const GET_USER_URI = (id: string | number) => `${BASE_URL}/v1/users/${id}`
export const GET_USERS_URI = (page?: number) =>
  page ? `${BASE_URL}/v1/users?per_page=10&page=${page}` : `${BASE_URL}/v1/users`
export const GET_USER_ENABLE_URI = (id: string | number) => `${BASE_URL}/v1/users/${id}/enable`
export const GET_USER_DISABLE_URI = (id: string | number) => `${BASE_URL}/v1/users/${id}/disable`
export const GET_ADMIN_USER_ENABLE_URI = (id: string | number) =>
  `${BASE_URL}/v1/admin/users/${id}/enable`
export const GET_ADMIN_USER_DISABLE_URI = (id: string | number) =>
  `${BASE_URL}/v1/admin/users/${id}/disable`
export const EXPORT_CSV = (id: number, errors?: boolean) =>
  `${BASE_URL}/v1/data_sets/${id}/export${errors ? '?errors_only=true' : ''}`
export const PREAUTH_URI = () => `${BASE_URL}/auth/preauth`
export const MAVENLINK_AUTH_URI = () => `${BASE_URL}/auth/mavenlink`
export const STOP_BLUEPRINT = (id: string) => `${BASE_URL}/v1/blueprints/${id}/stop`
export const TOKEN_URI = () => `${BASE_URL}/auth/token`
export const GET_PROFILE_URI = () => `${BASE_URL}/v1/users/me`
export const GET_MAVENLINK_USERS_URI = (name?: string) =>
  name ? `${BASE_URL}/v1/mavenlink_users?name=${name}` : `${BASE_URL}/v1/mavenlink_users`
export const GET_ADMIN_USERS_URI = (accountId?: string, pagination?: Pagination) => {
  const { page = 1, amount = 10, matching } = pagination || {}
  if (!accountId) return `${BASE_URL}/v1/admin/users`
  let url = `${BASE_URL}/v1/admin/users?page=${page}&per_page=${amount}&account_id=${accountId}`
  if (matching) {
    url += `&matching=${matching}`
  }
  return url
}
export const GET_ADMIN_USER_URI = (accId: string | number, userId: string | number) =>
  `${BASE_URL}/v1/admin/users/${userId}?account_id=${accId}`
