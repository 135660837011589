import valueSlice, { BlueprintValues } from './values'
import { GlobalState } from '..'
import actionSlice, { BlueprintServices } from './services'
import { NamedSet } from 'zustand/middleware'
import { GetState } from 'zustand'

const createSlice = (set: NamedSet<GlobalState>, get: GetState<GlobalState>) => ({
  ...valueSlice(),
  ...actionSlice(set, get),
})

export default createSlice
export type { BlueprintValues, BlueprintServices }
