import { DataSetTypes, BlueprintCategories } from '@interfaces'
import configs from '@configs'

const {
  configs: { home: HOME },
} = configs
export interface TabsValues {
  tabs: {
    blueprintCategories: BlueprintCategories[]
    subCurrentTab: DataSetTypes | null
    currentCategoryTab: BlueprintCategories
  }
}

const createSlice = (): TabsValues => ({
  tabs: {
    currentCategoryTab: HOME,
    subCurrentTab: null,
    blueprintCategories: [HOME],
  },
})

export default createSlice
