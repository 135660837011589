import { BlueprintCategories, BlueprintSort } from './Blueprint'
import {
  ValidateStates,
  KrushStates,
  SetStatus,
  DataSetPercentage,
  MavenlinkDataSetStates,
} from './Dataset'

export interface MutationCommonParams {
  uri: string
  action?: HTTPActions
}

export enum EnumSort {
  ASC = 'asc',
  DESC = 'desc',
}
export interface Pagination {
  matching?: string
  page?: number
  amount?: number
  sort?: {
    [k in BlueprintSort]?: EnumSort
  }
}

export interface NavigatorState {
  persistTabs?: boolean
}

export interface MetaBaseParams {
  count: number
  total_pages: number
  page: number
  per_page: number
}

export interface IMessagePayload {
  status: ValidateStates | KrushStates
  data_set_statuses: SetStatus
  data_set_percentage_completes: DataSetPercentage
  categories?: {
    [k in Exclude<BlueprintCategories, 'Home'>]: MavenlinkDataSetStates
  }
  percent_complete?: number
  processed_at?: string
  validated_at?: string
  next_step?: string
  updated_at: string
}

export enum EnumHTTPActions {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}
export type HTTPActions = 'POST' | 'PUT' | 'GET' | 'PATCH' | 'DELETE'
export type ResponseTypes = 'blob' | 'json' | 'status'
export enum EnumResponseTypes {
  JSON = 'json',
  BLOB = 'blob',
  STATUS = 'status',
}

// We can add more later on for now we only have text/csv;
export type BlobTypes = 'text/csv'
export enum EnumBlobTypes {
  CSV = 'text/csv',
}
export type BlobExtensions = 'csv'
export enum EnumBlobExtensions {
  CSV = 'csv',
}
export * from './Blueprint'
export * from './Dataset'
export * from './Record'
export * from './User'
export * from './Account'
