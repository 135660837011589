import Home from './Home'
import NotFound from './404'
import AdminContent from './Admins'
import AdminNew from './Admins/New'
import AdminEdit from './Admins/Edit'
import AdminUserNew from './Admins/Users/New'
import AdminUserEdit from './Admins/Users/Edit'
import Blueprint from './Blueprint'
import UserContentEdit from './Users/Edit'
import UserNew from './Users/New'
import Users from './Users'
import Login from './Login'
import Call from './Callback'
import Main from './'

const routes = {
  home: {
    path: '/',
    component: Home,
    navigate: () => '/',
  },

  blueprint: {
    path: '/blueprint/:id',
    component: Blueprint,
    navigate: (id: string | number) => `/blueprint/${id}`,
    newBlueprint: {
      path: '/blueprint/new',
      component: Blueprint,
      navigate: () => '/blueprint/new',
    },
  },

  notFound: {
    path: '*',
    component: NotFound,
  },

  admins: {
    path: '/admin',
    component: AdminContent,
    navigate: () => '/admin',
    newAdmin: {
      path: '/admin/new',
      component: AdminNew,
      navigate: () => '/admin/new',
    },
    editAdmin: {
      path: '/admin/accounts/:id/edit',
      component: AdminEdit,
      navigate: (id: string | number) => `/admin/accounts/${id}/edit`,
    },
    users: {
      newUser: {
        path: '/admin/accounts/:id/users/new',
        component: AdminUserNew,
        navigate: (id: string | number) => `/admin/accounts/${id}/users/new`,
      },
      editUser: {
        path: '/admin/accounts/:accId/users/:userId/edit',
        component: AdminUserEdit,
        navigate: (accId: string | number, userId: string | number) =>
          `/admin/accounts/${accId}/users/${userId}/edit`,
      },
    },
  },

  users: {
    path: '/settings/users',
    component: Users,
    navigate: () => `/settings/users`,
    newUser: {
      path: '/settings/users/new',
      component: UserNew,
      navigate: () => `/settings/users/new`,
    },
    editUser: {
      path: '/settings/users/edit/:id',
      component: UserContentEdit,
      navigate: (id: string | number) => `/settings/users/edit/${id}`,
    },
  },

  login: {
    path: '*',
    component: Login,
    navigate: () => '/login',
  },

  logout: {
    path: '/',
    component: Login,
    navigate: () => '/login',
  },

  callback: {
    path: '/callback',
    component: Call,
    navigate: () => '/callback',
  },

  main: {
    path: '/*',
    component: Main,
  },
}

export default routes
