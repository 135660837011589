import React, { memo, ChangeEvent, forwardRef } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { Autocomplete } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
interface IProps {
  label?: string
  outlined?: boolean
  name: string
  disable?: boolean
  value?: string | number
  placeholder?: string
  gutterBottom?: boolean
  onChange?: (e: string | ChangeEvent) => void
  error?: boolean
  focused?: boolean
  leftIcon?: string
  helperText?: string
  autoCompleteOptions?: string[]
}

const getIcon = (icon?: string) => {
  switch (icon) {
    case 'SearchIcon':
      return {
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }
    default:
      return
  }
}

const Text = forwardRef(
  ({ disable, outlined, leftIcon, gutterBottom, autoCompleteOptions, ...props }: IProps, ref) => {
    const commonProps = {
      style: {
        marginBottom: gutterBottom ? '1.5em' : 0,
      },
      disabled: disable,
      variant: outlined ? 'outlined' : 'standard',
      size: 'small',
      inputRef: ref,
      ...props,
    } as TextFieldProps

    if (autoCompleteOptions) {
      return (
        <Autocomplete
          id="combo-box-demo"
          options={autoCompleteOptions}
          sx={{ width: 300 }}
          autoHighlight
          renderInput={(params) =>
            leftIcon ? (
              <TextField
                {...params}
                InputProps={{ ...params.InputProps, ...getIcon(leftIcon) }}
                {...commonProps}
              />
            ) : (
              <TextField {...params} InputProps={params.InputProps} {...commonProps} />
            )
          }
        />
      )
    } else {
      return leftIcon ? (
        <TextField InputProps={getIcon(leftIcon)} {...commonProps} />
      ) : (
        <TextField {...commonProps} />
      )
    }
  }
)

export default memo(Text)
