import React, { memo, forwardRef, ForwardedRef } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'

interface IProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  label: string
  value?: String
  checked?: boolean
  blocked?: boolean
  name: string
}
const CheckboxMemoized = forwardRef(
  ({ onChange, label, blocked, ...props }: IProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <FormControlLabel
        label={label}
        sx={{
          marginRight: 0,
        }}
        componentsProps={{
          typography: {
            fontWeight: 'normal',
            color: 'inherit !important',
          },
        }}
        control={
          <Checkbox ref={ref} onChange={onChange} disabled={blocked} color="primary" {...props} />
        }
      />
    )
  }
)

export default memo(CheckboxMemoized)
