import { useState, useEffect, useMemo } from 'react'
import { useStore } from '@hooks'
import { useQuery, useMutation } from 'react-query'
import Switch from '@mui/material/Switch'
import { Box, CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Table, Title, Tooltip, MainButton } from '@components'
import { useAlert } from 'react-alert'
import { GET_ACCOUNT_URI, GET_EMAIL_URI, GET_USERS_URI } from '@uris'
import { shallow } from 'zustand/shallow'
import { EnumMavenlinkPermisions, IUser } from '@interfaces'
import routes from '@routes'
import configs from '@configs'
import { formatDate, removeErrorPreffix } from '@utils'

interface IProps {
  user: IUser
}

const userSelectors = ['name', 'mavenlink_user_id', 'created_at', 'updated_at', 'state'] as Partial<
  keyof IUser
>[]

function UserContent({ user }: IProps) {
  const {
    pages: { Users: root },
    configs: {
      cache: { TABLE_CONFIG_FETCH, ALWAYS_CACHE },
    },
  } = configs
  const emailChangeAlert = useAlert()
  const [tooltip, setTooltip] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [mutation, getUsers, getAccount] = useStore(
    (state) => [state.mutation, state.getUsers, state.getAccount],
    shallow
  )
  const navigate = useNavigate()
  const [emailchecked, setEmailchecked] = useState(false)
  const { mutate: changeEmailSupression } = useMutation(mutation, {
    onError: (error: Error) => {
      emailChangeAlert.error(removeErrorPreffix(error.message))
    },
  })

  const handleChangeEmail = () => {
    if (
      user.mavenlink_permissions === EnumMavenlinkPermisions.ADMIN ||
      user.mavenlink_permissions === EnumMavenlinkPermisions.OWNER
    ) {
      setEmailchecked(!emailchecked)
      changeEmailSupression({ uri: GET_EMAIL_URI() })
    } else {
      setTooltip(true)
    }
  }
  const handleClick = () => {
    navigate(routes.users.newUser.navigate())
  }

  const { data } = useQuery([`${GET_USERS_URI(page)}`], () => getUsers(page), {
    ...TABLE_CONFIG_FETCH,
    staleTime: 0,
    select: (response) => {
      return {
        ...response,
        users: response.users.map((userData) => {
          return {
            ...userData,
            created_at: formatDate(userData.created_at),
            updated_at: formatDate(userData.updated_at),
          }
        }),
      }
    },
  })
  const { data: account, isLoading } = useQuery([`${GET_ACCOUNT_URI()}`], () => getAccount(), {
    ...ALWAYS_CACHE,
  })

  const parsedData = useMemo(() => {
    return data?.users.map((fetchedUser) => {
      return {
        id: fetchedUser.id,
        data: userSelectors.map((header) => ({ value: fetchedUser[header] })),
        action: () => navigate(routes.users.editUser.navigate(fetchedUser.id)),
      }
    })
  }, [data?.users])

  const parsedHeaders = useMemo(() => {
    return root.userGridHeaders.map((header) => ({ data: header }))
  }, [])

  useEffect(() => {
    setEmailchecked(!account?.email_notification_suppression)
  }, [account?.email_notification_suppression])

  return (
    <main>
      <section>
        <Title header={root.accountSettingsTitle} variant="h5" />
        <Box mt={3} mb={5} display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Title variant="subtitle1" header={root.emailNotifications} />
            {isLoading && !account ? (
              <CircularProgress />
            ) : (
              <>
                {tooltip ? (
                  <Tooltip title={root.notAuthorized} arrow>
                    <Switch
                      color={'primary'}
                      checked={emailchecked}
                      inputProps={{ 'aria-label': 'Email Notifications' }}
                    />
                  </Tooltip>
                ) : (
                  <Switch
                    color="primary"
                    checked={emailchecked}
                    onChange={handleChangeEmail}
                    inputProps={{ 'aria-label': 'Email Notifications' }}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={3} mb={2.5}>
          <Title variant="h6" header={root.gridTitle} />
          <MainButton
            title={root.mainButtonLabel}
            type="submit"
            color="primary"
            className="buttonn"
            onClick={handleClick}
          />
        </Box>
        {data && parsedData && (
          <Table
            data={parsedData}
            headers={parsedHeaders}
            page={page}
            setPage={setPage}
            totalPages={data.meta.total_pages}
            boldedHeaders
          />
        )}
      </section>
    </main>
  )
}
export default UserContent
