import { useMemo, memo, useState, ComponentProps } from 'react'
import {
  BlueprintTabs,
  MavenlinkObject,
  RecordTypes,
  KrushStates,
  ValidateStates,
  EnumKrushStates,
  BlueprintCategories,
  DataSetTypes,
} from '@interfaces'
import { CheckCircle, WarningRounded } from '@mui/icons-material'
import {
  Checkbox,
  Text,
  MainButton,
  DeterminateProgress,
  Modal,
  Accordion,
  Tooltip,
  Card,
  Title,
} from '@components'
import { useFormik } from 'formik'
import InfoIcon from '@mui/icons-material/Info'
import { useQuery, useMutation } from 'react-query'
import { useStore, useBlueprintStatus, useFormikScrollError } from '@hooks'
import { shallow } from 'zustand/shallow'
import {
  GET_MAVENLINK_OBJECTS,
  PROCESS_BLUEPRINT_URI,
  STOP_BLUEPRINT,
  VALIDATE_BLUEPRINT_URI,
} from '@uris'
import { formatDate, removeErrorPreffix } from '@utils'
import {
  CircularProgress,
  Typography,
  Box,
  useTheme,
  IconButton,
  SxProps,
  Theme,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAlert } from 'react-alert'
import configs from '@configs'
import routes from '@routes'
import * as Yup from 'yup'
import './index.scss'

const validationSchema = Yup.object({
  name: Yup.string().required(configs.pages.Blueprint.HomeTab.requiredBlueprint),
})
interface IProps {
  labels: BlueprintTabs
  id?: string
  progressStatus?: number | null
  onlineStatus: KrushStates | ValidateStates
  processedTimeStamp?: string
  validatedTimeStamp?: string
  validationStartedAtTimeStamp?: string
  processingStartedAtTimeStamp?: string
  createdBlueprintTabs?: BlueprintCategories[]
  nextStep?: string
  dataSetsAmount?: number
  creator?: string
  createdAt?: string
  updatedAt?: string
}

interface IInitialValues {
  checked: RecordTypes[]
  name?: string
}

const CARD_HEADERS = [
  'STATUS',
  'NEXT STEP',
  'DATA SETS',
  'LAST UPDATED',
  'CREATOR',
  'CREATED AT',
  'PERCENT COMPLETE',
  'VALIDATION STARTED AT',
  'PROCESSING STARTED AT',
]

const HomeContent = ({
  labels,
  id,
  onlineStatus,
  processedTimeStamp,
  validatedTimeStamp,
  validationStartedAtTimeStamp,
  processingStartedAtTimeStamp,
  progressStatus,
  createdBlueprintTabs,
  nextStep,
  dataSetsAmount,
  creator,
  createdAt,
  updatedAt,
}: IProps) => {
  const {
    configs: {
      cache: { LONG_CACHE },
      colors: { GRAY_02 },
    },
    pages: {
      Blueprint: {
        HomeTab: root,
        Dataset: { prettifyOnlineStatus },
      },
    },
    core,
    configs: { colors, home },
  } = configs
  const contextTheme = useTheme()
  const [cancelModal, setCancelModal] = useState<boolean>(false)
  const customizedAlert = useAlert()
  const navigate = useNavigate()
  const {
    isValidating,
    isValidatedWithErrors,
    isProcessing,
    isValidated,
    isProcessed,
    isCreated,
    isProcessedWithErrors,
    isCancelled,
  } = useBlueprintStatus(onlineStatus)
  if (cancelModal && !(isProcessing || isValidating)) {
    setCancelModal(false)
  }
  const [setBluePrint, mutation, getMavenLinkObjects, setCurrentCategoryTab] = useStore(
    (state) => [
      state.createBlueprint,
      state.mutation,
      state.getMavenLinkObjects,
      state.setCurrentCategoryTab,
    ],
    shallow
  )
  const { data: mavenLinkObjectsData, isLoading } = useQuery(
    [GET_MAVENLINK_OBJECTS()],
    () => getMavenLinkObjects(),
    {
      ...LONG_CACHE,
      refetchOnWindowFocus: false,
    }
  )

  const { mutate: mutateBlueprint, isLoading: mutateLoading } = useMutation(setBluePrint, {
    onSuccess: (blueprint) => {
      customizedAlert.success(root.createdNewBlueprintSuccess)
      navigate(routes.blueprint.navigate(blueprint.id), { replace: true })
    },
    onError: (error: Error) => {
      customizedAlert.error(removeErrorPreffix(error.message))
    },
  })

  const { mutate } = useMutation(mutation, {
    onError: (error: Error) => {
      customizedAlert.error(removeErrorPreffix(error.message))
    },
  })

  const date = useMemo(() => {
    if (validatedTimeStamp && !processedTimeStamp) {
      return formatDate(validatedTimeStamp)
    } else if (processedTimeStamp) {
      return formatDate(processedTimeStamp)
    } else {
      return root.dateError
    }
  }, [processedTimeStamp, validatedTimeStamp])

  const blueprintRecords = useMemo(() => {
    if (!id) return []
    const { Home, ...otherLabels } = labels
    const currentTabsOnline = Object.keys(otherLabels) as Exclude<BlueprintCategories, 'Home'>[]
    const mavenlinkRecordTypes = currentTabsOnline.reduce((accum, val) => {
      const otherLabelValues = otherLabels[val]
      if (!otherLabelValues) return accum
      const categoryKeys = Object.keys(otherLabelValues) as DataSetTypes[]
      return [
        ...accum,
        ...categoryKeys.map((category) => otherLabelValues[category].mavenlink_record_type),
      ]
    }, [] as RecordTypes[])
    return mavenlinkRecordTypes
  }, [labels, id])

  const handleCreate = (values: IInitialValues) => {
    if (values.checked.length === 0) {
      customizedAlert.error(removeErrorPreffix(root.minDataSetRequired))
      return
    }
    const mavenLinkRecordTypes = values.checked as RecordTypes[]
    const filteredMavenlinkObjects = mavenLinkObjectsData?.mavenlink_objects?.filter((obj) =>
      mavenLinkRecordTypes.includes(obj.name)
    ) as MavenlinkObject[]
    mutateBlueprint({ options: filteredMavenlinkObjects, name: values.name })
    setCurrentCategoryTab(home)
  }

  const handleStop = () => {
    setCancelModal(false)
    customizedAlert.info(isValidating ? root.stopValidationInit : root.cancelBlueprintProcess)
    mutate({ uri: STOP_BLUEPRINT(id as string) })
  }

  const formik = useFormik({
    initialValues: {
      checked: blueprintRecords,
      name: labels.Home.name,
      autoProcess: false,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (id && (isCreated || isValidatedWithErrors)) {
        return mutate({ uri: VALIDATE_BLUEPRINT_URI(id, values.autoProcess) })
      }

      if (id && isValidated) {
        return mutate({ uri: PROCESS_BLUEPRINT_URI(id) })
      }
      return handleCreate(values)
    },
    validationSchema,
  })

  useFormikScrollError(formik)

  const isCreatedBlueprint = id && formik.values.name ? true : false

  const categoriesHeaders = isCreatedBlueprint
    ? createdBlueprintTabs
    : mavenLinkObjectsData?.categories

  const shouldDisplayAutoProcess = id && !isProcessing && !isProcessed && !isValidated

  const getCardValue = (index: number) => {
    const CardContent = ({ header = '--', ...props }: Partial<ComponentProps<typeof Title>>) => (
      <Title
        textAlign="center"
        variant="h6"
        flex={1}
        fontWeight={(theme) => theme.typography.fontWeightBold}
        header={header}
        {...props}
      />
    )
    const longWordSx: SxProps<Theme> = {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      wordBreak: 'word-break',
    }
    switch (index) {
      case 0:
        return <CardContent header={prettifyOnlineStatus(onlineStatus)} />
      case 1:
        return <CardContent header={nextStep} sx={longWordSx} />
      case 2:
        return <CardContent header={String(dataSetsAmount)} />
      case 3:
        return (
          <CardContent header={updatedAt ? formatDate(updatedAt) : undefined} variant="body1" />
        )
      case 4:
        return <CardContent header={creator} sx={longWordSx} />
      case 5:
        return (
          <CardContent header={createdAt ? formatDate(createdAt) : undefined} variant="body1" />
        )
      case 6:
        return (
          <CardContent header={progressStatus ? `${progressStatus}%` : undefined} variant="body1" />
        )
      case 7:
        return (
          <CardContent
            header={
              validationStartedAtTimeStamp ? formatDate(validationStartedAtTimeStamp) : undefined
            }
            variant="body1"
          />
        )
      case 8:
        return (
          <CardContent
            header={
              processingStartedAtTimeStamp ? formatDate(processingStartedAtTimeStamp) : undefined
            }
            variant="body1"
          />
        )
      default:
        return <CardContent />
    }
  }

  return isLoading || mutateLoading ? (
    <CircularProgress />
  ) : (
    <Box display="flex">
      <form
        style={{
          marginTop: id ? contextTheme.spacing(1) : contextTheme.spacing(4),
          minWidth: '30em',
        }}
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Modal
          open={cancelModal}
          title={root.confirmationTitle}
          onClose={() => setCancelModal(false)}
          cancelButton={
            <MainButton
              title={core.cancel}
              variant="outlined"
              onClick={() => setCancelModal(false)}
            />
          }
          confirmButton={<MainButton title={core.accept} variant="outlined" onClick={handleStop} />}
        >
          <Typography>{isValidating ? root.stopValidationDesc : root.cancelProcessDesc}</Typography>
        </Modal>
        {!id && (
          <Text
            name="name"
            value={formik.values.name || ''}
            disable={false}
            label={(formik.touched.name && formik.errors.name) || root.blueprintName}
            outlined
            gutterBottom
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
          />
        )}
        {mavenLinkObjectsData &&
          categoriesHeaders?.map((category) => (
            <Accordion key={`${category}_${id}`} header={category} expanded={isCreatedBlueprint}>
              {mavenLinkObjectsData.mavenlink_objects.map(
                ({ display_name, name, categories }) =>
                  (blueprintRecords.includes(name) || !id) &&
                  categories.includes(category) && (
                    <Checkbox
                      key={`Check ${display_name}${id}`}
                      name="checked"
                      value={name}
                      label={display_name}
                      onChange={formik.handleChange}
                      checked={
                        id ? blueprintRecords.includes(name) : formik.values.checked.includes(name)
                      }
                      blocked={Boolean(id)}
                    />
                  )
              )}
            </Accordion>
          ))}
        <Box
          mt={(theme) => theme.spacing(2)}
          display="flex"
          flexDirection="column"
          gap={(theme) => theme.spacing(2)}
          alignItems="flex-start"
        >
          {onlineStatus !== EnumKrushStates.UPLOADED && !isProcessedWithErrors && !isCancelled && (
            <Box display="flex" width="100%" gap={(theme) => theme.spacing(2)} alignItems="center">
              <MainButton
                type="submit"
                title={id ? root.getDisplayButtonState(onlineStatus) : root.startNewBlueprint}
                color="primary"
                disabled={isValidating || isProcessing}
              />
              {(isValidating || isProcessing) && (
                <MainButton
                  title={isValidating ? root.stopValidate : root.stopProcess}
                  color="secondary"
                  variant="outlined"
                  onClick={() => setCancelModal(true)}
                />
              )}
              {shouldDisplayAutoProcess && (
                <Box display="flex" alignItems="center">
                  <Checkbox
                    name="autoProcess"
                    label={root.autoProcessLabel}
                    onChange={formik.handleChange}
                    blocked={isValidating}
                  />
                  <Tooltip title={root.autoProcessTooltip} placement="top-start" arrow>
                    <IconButton disableRipple disableFocusRipple size="small">
                      <InfoIcon fontSize="small" style={{ fill: GRAY_02 }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
          )}
        </Box>
        {(isValidated ||
          isProcessed ||
          isValidatedWithErrors ||
          isProcessedWithErrors ||
          isCancelled) && (
          <Box className="globalStatusContainer">
            {isValidatedWithErrors || isProcessedWithErrors || isCancelled ? (
              <WarningRounded style={{ fill: isCancelled ? colors.GRAY_01 : colors.WARNING }} />
            ) : (
              <CheckCircle style={{ fill: colors.SUCCESS }} />
            )}
            <Typography paragraph>
              {root.getDisplayLabelState(onlineStatus)}
              {date}
            </Typography>
          </Box>
        )}
        {(isValidating || isProcessing) && (
          <DeterminateProgress
            progress={progressStatus || 0}
            outerBoxProps={{ marginTop: contextTheme.spacing(3) }}
          />
        )}
      </form>
      {id && (
        <Box width="100%" display="flex" justifyContent="center">
          <Box
            marginTop={2}
            display="grid"
            gridTemplateColumns="repeat(3, 120px)"
            gridTemplateRows="repeat(3, 160px)"
            columnGap={3}
            rowGap={2}
          >
            {CARD_HEADERS.map((value, index) => (
              <Card
                key={value}
                title={value}
                sxChildrenBoxProps={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {getCardValue(index)}
              </Card>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default memo(HomeContent)
