import {
  KrushStates,
  UploadStates,
  ValidateStates,
  EnumUploadStates,
  EnumValidateStates,
  EnumKrushStates,
  BlueprintCategories,
} from '@interfaces'

import * as Yup from 'yup'

const main = {
  configs: {
    home: 'Home' as BlueprintCategories,
    auth: 'Auth',
    preAuth: 'preauth_token',
    debounceSearchTime: 300,
    colors: {
      WARNING: '#F88115',
      SUCCESS: '#44b76c',
      SUCCESS_02: '#4AA321',
      DANGER: '#EE534C',
      GRAY_01: '#9e9e9e',
      GRAY_03: '#dadada',
      GRAY_02: '#c6c6c6',
      GRAY_04: '#ECECEC',
      GRAY_05: 'rgba(0, 0, 0, 0.03)',
      DARK_BLUE: '#0c1432',
      WHITE: '#FFFF',
      NEW_PRIMARY: '#4a3f72',
      PRIMARY_PAPER: '#F7F7F7',
      CHARCOAL: '#212121',
      PRIMARY: '#448FEA',
      SECONDARY: '#f50057',
      PRIMARY_02: '#9AC8FF',
      BLACK_01: ' #333333',
    },
    validationSchemas: {
      user: Yup.object({
        name: Yup.string().required('User name is required'),
      }),
      admin: {
        user: Yup.object({
          name: Yup.string().required('User name is required'),
          mavenlinkUserId: Yup.number()
            .typeError('User ID must be a number')
            .integer('User ID must be an integer')
            .required('User ID is required'),
        }),
        edit: Yup.object({
          name: Yup.string().required('Account name is required'),
          id: Yup.number()
            .typeError('Account ID must be a number')
            .integer('Account ID must be an integer')
            .required('Account ID is required'),
        }),
        new: Yup.object({
          id: Yup.number()
            .typeError('Account ID must be a number')
            .integer('Account ID must be an integer')
            .required('Account ID is required'),
          name: Yup.string().required('Account name is required'),
          userId: Yup.number()
            .typeError('Account ID must be a number')
            .integer('Account ID must be an integer')
            .required('Account ID is required'),
          userName: Yup.string().required('Account name is required'),
        }),
      },
    },
    cache: {
      ALWAYS_CACHE: {
        staleTime: Infinity,
      },
      // 5 minutes
      LONG_CACHE: {
        staleTime: 300000,
      },
      // 1 minute
      MEDIUM_CACHE: {
        staleTime: 60000,
      },
      // 30 seconds
      SMALL_CACHE: {
        staleTime: 30000,
      },
      // 15 seconds
      DEFAULT_CACHE: {
        staleTime: 15000,
      },

      TABLE_CONFIG_FETCH: {
        staleTime: 30000,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      },
    },
  },
  core: {
    template: 'Template',
    accept: 'Yes',
    cancel: 'No',
    viewOnly: 'This user is not authorized to use Smart Upload Manager',
    defaultPlaceHolder: '-',
    home: 'Home',
    dateFormatterWithoutSeconds: {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    } as Intl.DateTimeFormatOptions,
    migrationMaxDays: 180,
    trialMaxDays: 30,
    defaultMinDays: 7,
  },
  layouts: {
    NavBar: {
      blueprints: 'Recent Blueprints',
      newBlueprint: ' New Blueprint',
    },
    Header: {
      successLogut: 'Signed out',
      trialMessage:
        'Welcome to the Smart Upload Manager limited trial. Trial account data sets are limited to 200 rows. Please contact your sales rep or CSM to upgrade.',
      home: 'Home',
      adminPanel: 'Admin Panel',
      accoutSettings: 'Account Settings',
      logOut: 'Sign Out',
    },
  },
  components: {
    inputs: {
      Upload: {
        uploadSuccess: 'Uploading File',
        dragCsv: 'Drag CSV here or',
        uploadingCsv: (file: File) => `Uploading ${file.name.split('.')[0]}.csv`,
        uploadCsv: 'Upload CSV',
      },
    },
  },
  pages: {
    Admins: {
      gridHeaders: [
        'Name',
        'Kantata OX Account ID',
        'Account Type',
        'Provisioned At',
        'Last Modified At',
        'Expires At',
        'Status',
      ],
      provisionAccountTitle: 'Provisioned Accounts',
      searchPlaceholder: 'Name or Account ID',
      provisionAccountButtonText: 'Provision Account',
      Edit: {
        editAccountTitle: 'Edit Account',
        accountIdTextBoxTitle: 'Kantata OX Account ID',
        accountNameTextBoxTitle: 'Account name',
        accountTypeTextBoxTitle: 'Account Type',
        accountTypesArray: ['Client', 'Internal', 'Migration', 'Trial'],
        saveButtonText: 'Save',
        cancelButtonText: 'Cancel',
        disableAccountButtonText: 'Disable Account',
        enableAccountButtonText: 'Enable Account',
        Users: {
          mainButtonLabel: 'Provision User',
          inputPlaceholder: 'Name or User ID',
          userGridHeaders: [
            'Name',
            'Kantata OX User ID',
            'Role',
            'Provisioned At',
            'Last Modified At',
            'Status',
          ],
          gridTitle: 'Provisioned Users',
        },
      },
      New: {
        provisionAccountTitle: 'Provision New Account',
        accountIdTextBoxTitle: 'Kantata OX Account ID',
        accountNameTextBoxTitle: 'Account Name',
        accountTypeTextBoxTitle: 'Account Type',
        accountTypesArray: ['Client', 'Internal', 'Migration', 'Trial'],
        provisionAccountButtonText: 'Provision Account',
        cancelButtonText: 'Cancel',
        userIdTextBoxTitle: 'Primary User ID',
        userNameTextBoxTitle: 'Primary User Name',
      },
      Users: {
        roles: ['Customer', 'Employee Admin'],
        New: {
          nameLabel: 'Name',
          mavelinkIdLabel: 'Kantata OX User ID',
          mainButtonLabel: 'Provision User',
          title: 'Provision New User',
          cancelButtonLabel: 'Cancel',
        },
        Edit: {
          title: 'Edit User',
          nameLabel: 'Name',
          mavelinkIdLabel: 'Kantata OX User ID',
          mainButtonLabel: 'Save',
          cancelButtonLabel: 'Cancel',
          enable: 'Enable User',
          disable: 'Disable User',
          roles: ['Customer', 'Employee Admin'],
          roleLabel: 'Role',
        },
      },
    },
    Blueprint: {
      home: 'Home',
      newBlueprint: 'New Blueprint',
      Dataset: {
        replace: 'Replace',
        successReplace: 'Data set reset',
        downloadTemplate: 'Download Template',
        exportDataSet: 'Export Data Set',
        tooltipErrorMessage: 'Click to view error messages',
        errorLog: 'Error Log:',
        exportSetErrors: 'Export Errors',
        prettifyOnlineStatus: (state: UploadStates | KrushStates | ValidateStates) => {
          switch (state) {
            case EnumUploadStates.UPLOADING:
              return 'Uploading'
            case EnumUploadStates.UPLOADED:
              return 'Uploaded'
            case EnumValidateStates.UPLOADING:
              return 'Validating'
            case EnumValidateStates.UPLOADED:
              return 'Validated'
            case EnumValidateStates.ERROR:
              return 'Validated With Errors'
            case EnumKrushStates.UPLOADING:
              return 'Processing'
            case EnumKrushStates.UPLOADED:
              return 'Processed'
            case EnumKrushStates.ERROR:
              return 'Processed With Errors'
            case EnumKrushStates.CANCELLED:
              return 'Canceled'
            default:
              return 'Created'
          }
        },
      },
      HomeTab: {
        validatedAt: 'Validated at \t',
        processedAt: 'Processed at \t',
        processedWithErrors: 'Processed with errors at \t',
        validatedWithErrors: 'Validated with errors at \t',
        cancelledBlueprint: 'Canceled at \t',
        newBlueprint: 'New Blueprint',
        startNewBlueprint: 'Start Blueprint',
        createdNewBlueprintSuccess: 'Blueprint created',
        continue: 'Continue',
        validate: 'Validate',
        validating: 'Validating',
        autoProcessLabel: 'Auto-process',
        autoProcessTooltip:
          'Process blueprint automatically if validation completes without errors',
        processing: 'Processing',
        process: 'Process',
        dateError: 'Error in getting date',
        requiredBlueprint: 'Name is required',
        minDataSetRequired: 'At least one object type is required to start a blueprint',
        stopValidate: 'Stop',
        stopProcess: 'Cancel',
        confirmationTitle: 'Are you sure?',
        stopValidationDesc:
          'This will stop all remaining validations for this blueprint. Once stopped, validation can be restarted.',
        cancelProcessDesc:
          'This will cancel all remaining records for processing in this blueprint. Once canceled, this blueprint cannot be processed again.',
        blueprintName: ' Blueprint Name',
        stopValidationInit: 'Stopping Validation',
        cancelBlueprintProcess: 'Canceling Blueprint',
        getDisplayButtonState: (status: Exclude<KrushStates, 'processed'> | ValidateStates) => {
          switch (status) {
            case EnumValidateStates.UPLOADING:
              return 'Validating'
            case EnumKrushStates.UPLOADING:
              return 'Processing'
            case EnumValidateStates.UPLOADED:
              return 'Process'
            default:
              return 'Validate'
          }
        },

        getDisplayLabelState(status: KrushStates | ValidateStates) {
          switch (status) {
            case EnumValidateStates.ERROR:
              return 'Validated with errors at \t'
            case EnumKrushStates.UPLOADED:
              return 'Processed at \t'
            case EnumKrushStates.ERROR:
              return 'Processed with errors at \t'
            case EnumKrushStates.CANCELLED:
              return 'Canceled at \t'
            default:
              return 'Validated at \t'
          }
        },
      },
    },
    Home: {
      header: 'Smart Upload Manager',
      label: 'Blueprint Name',
      tableHeaders: [
        {
          key: 'name',
          value: 'BLUEPRINT',
        },
        {
          key: 'status',
          value: 'STATUS',
        },
        {
          key: 'service_user_id',
          value: 'CREATOR',
        },
        {
          key: 'created_at',
          value: 'DATE CREATED',
        },
        {
          key: 'processed_at',
          value: 'PROCESSED AT',
        },
        {
          key: 'updated_at',
          value: 'LAST UPDATED',
        },
        {
          key: 'data_sets_count',
          value: 'DATA SETS',
        },
      ],
    },
    Login: {
      buttonText: 'Sign in with Kantata OX',
      header: 'Smart Upload Manager',
    },
    Users: {
      userGridHeaders: [
        'Name',
        'Kantata OX User ID',
        'Provisioned At',
        'Last Modified At',
        'Status',
      ],
      accountSettingsTitle: 'Account Settings',
      emailNotifications: 'Account Email Notifications',
      mainButtonLabel: 'Provision User',
      gridTitle: 'Provisioned Users',
      inputPlaceholder: 'Name or User ID',
      notAuthorized: 'Only Kantata OX administrators can modify this setting',
      New: {
        newUserTitle: 'Provision New User',
        userIdBoxLabel: 'Kantata OX User ID',
        mavenlinkUserBoxLabel: 'Kantata OX User',
        userNameBoxLabel: 'User Name',
        provisionUserButtonText: 'Provision User',
        cancelButtonText: 'Cancel',
        emptyMavenLinkUserErrorMessage: 'Kantata OX User cannot be empty',
        helperText: 'Type Kantata OX User Name',
        selectFromDropdownErrorMessage:
          'A valid Kantata OX User must be selected from the dropdown',
      },
      Edit: {
        editUserTitle: 'Edit User',
        userIdBoxLabel: 'Kantata OX User ID',
        userNameBoxLabel: 'User Name',
        saveButtonText: 'Save',
        cancelButtonText: 'Cancel',
        disableUserButtonText: 'Disable User',
        enableUserButtonText: 'Enable User',
      },
    },
  },
}

export default main
