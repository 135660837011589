import {
  KrushStates,
  ValidateStates,
  EnumValidateStates,
  EnumKrushStates,
  UploadStates,
  EnumUploadStates,
} from '@interfaces'
const useBlueprintStatus = (onlineStatus: KrushStates | ValidateStates | UploadStates) => {
  const [
    isValidating,
    isProcessing,
    isValidated,
    isProcessed,
    isCreated,
    isProcessedWithErrors,
    isUploading,
    isUploaded,
    isValidatedWithErrors,
    isCancelled,
  ] = [
    onlineStatus === EnumValidateStates.UPLOADING,
    onlineStatus === EnumKrushStates.UPLOADING,
    onlineStatus === EnumValidateStates.UPLOADED,
    onlineStatus === EnumKrushStates.UPLOADED,
    onlineStatus === EnumValidateStates.CREATED,
    onlineStatus === EnumKrushStates.ERROR,
    onlineStatus === EnumUploadStates.UPLOADING,
    onlineStatus === EnumUploadStates.UPLOADED,
    onlineStatus === EnumValidateStates.ERROR,
    onlineStatus === EnumKrushStates.CANCELLED,
  ]

  return {
    isValidating,
    isProcessing,
    isValidated,
    isProcessed,
    isCreated,
    isProcessedWithErrors,
    isUploading,
    isUploaded,
    isValidatedWithErrors,
    isCancelled,
  }
}

export default useBlueprintStatus
