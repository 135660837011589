import { GetState } from 'zustand'
import { NamedSet } from 'zustand/middleware'
import { GlobalState } from '..'
import createServiceSlice, { TabsServices } from './services'
import createValueSlice, { TabsValues } from './values'

const createSlice = (set: NamedSet<GlobalState>, get: GetState<GlobalState>) => ({
  ...createServiceSlice(set, get),
  ...createValueSlice(),
})

export default createSlice
export type { TabsValues, TabsServices }
