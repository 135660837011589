import { memo, useMemo } from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
interface IProps {
  label?: string
  inputFormat?: string
  value: string | Date
  onChange: (e: Date | number | string) => void
  minDate?: number | string
  maxDate?: number | string
}

const DatePicker = ({
  label = 'Date',
  inputFormat = 'MM/dd/yyyy',
  minDate,
  maxDate,
  value,
  ...props
}: IProps) => {
  const [minimumDate, maximumDate, valueParsed] = useMemo(() => {
    let minDateParsed
    let maxDateParsed
    let valueDate = new Date(value)
    if (typeof minDate === 'string') {
      minDateParsed = new Date(minDate)
    } else if (typeof minDate === 'number') {
      minDateParsed = minDate
    } else {
      minDateParsed = new Date()
    }
    if (typeof maxDate === 'string') {
      maxDateParsed = new Date(maxDate)
    } else if (typeof maxDate === 'number') {
      maxDateParsed = maxDate
    }
    return [minDateParsed, maxDateParsed, valueDate]
  }, [minDate, maxDate, value])

  const handleChange = (date: number | Date | string | null) => {
    if (date) {
      props.onChange(new Date(date))
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        {...props}
        label={label}
        onChange={(date) => handleChange(date)}
        value={valueParsed}
        minDate={new Date(minimumDate)}
        maxDate={maximumDate ? new Date(maximumDate) : undefined}
      />
    </LocalizationProvider>
  )
}
export default memo(DatePicker)
