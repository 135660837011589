import { Text, MainButton, Title, Dropdown } from '@components'
import configs from '@configs'
import { Box } from '@mui/material'
import { useFormik } from 'formik'
import { useQuery, useMutation } from 'react-query'
import { GET_ADMIN_USER_URI, GET_ADMIN_USER_DISABLE_URI, GET_ADMIN_USER_ENABLE_URI } from '@uris'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from '@hooks'
import routes from '@routes'
import { shallow } from 'zustand/shallow'
import { useAlert } from 'react-alert'
import { useMemo } from 'react'
import { EnumRoles, EnumAccountUserStates, EnumHTTPActions } from '@interfaces'
import { removeErrorPreffix } from '@utils'

const EditUser = () => {
  const {
    configs: { validationSchemas },
    pages: {
      Admins: {
        Users: { Edit: root },
      },
    },
  } = configs

  const customizedAlert = useAlert()
  const navigate = useNavigate()
  const { userId, accId } = useParams()
  const [getAdminUser, editAdminUser, mutation] = useStore(
    (state) => [state.getAdminUser, state.editAdminUser, state.mutation],
    shallow
  )
  const { data } = useQuery(
    [GET_ADMIN_USER_URI(accId as string, userId as string)],
    () => getAdminUser(accId as string, userId as string),
    {
      enabled: Boolean(userId && accId),
    }
  )
  const { mutate } = useMutation(editAdminUser, {
    onSuccess: () => {
      if (accId) navigate(routes.admins.editAdmin.navigate(accId))
    },
    onError: (error: Error) => {
      customizedAlert.error(removeErrorPreffix(error.message.replace(',', ', ')))
    },
  })

  const { mutate: handleAccountState } = useMutation(mutation, {
    onSuccess: () => {
      if (accId) navigate(routes.admins.editAdmin.navigate(accId))
    },
    onError: (error: Error) => {
      customizedAlert.error(removeErrorPreffix(error.message.replace(',', ', ')))
    },
  })

  const formik = useFormik({
    initialValues: {
      name: data?.name,
      mavenlinkUserId: data?.mavenlink_user_id,
      role: data?.role,
      status: data?.state,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const { name, mavenlinkUserId, role } = values
      if (name && mavenlinkUserId && role && userId && accId)
        mutate({
          name,
          mavenlinkUserId: String(mavenlinkUserId),
          role:
            parsedValue.toLowerCase() === EnumRoles.CUSTOMER ? EnumRoles.CUSTOMER : EnumRoles.ADMIN,
          userId,
          accId,
        })
    },
    validationSchema: validationSchemas.admin.user,
  })

  const handleCancel = () => {
    if (accId) navigate(routes.admins.editAdmin.navigate(accId))
  }

  const parsedValue = useMemo(() => {
    if (formik.values.role?.toLowerCase() === EnumRoles.CUSTOMER) return root.roles[0]
    return root.roles[1]
  }, [formik.values.role])

  const isUserEnabled = formik.values.status === EnumAccountUserStates.ACTIVE

  return (
    <main>
      <section>
        <Title header={root.title} variant="h5" />
        <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
          <Text
            value={formik.values.name}
            onChange={formik.handleChange}
            name="name"
            gutterBottom
            outlined
            focused
            error={formik.touched.name && Boolean(formik.errors.name)}
            label={(formik.touched.name && formik.errors.name) || root.nameLabel}
          />
          <Text
            value={formik.values.mavenlinkUserId}
            onChange={formik.handleChange}
            name="mavenlinkUserId"
            gutterBottom
            outlined
            focused
            error={formik.touched.mavenlinkUserId && Boolean(formik.errors.mavenlinkUserId)}
            label={
              (formik.touched.mavenlinkUserId && formik.errors.mavenlinkUserId) ||
              root.mavelinkIdLabel
            }
          />
          <Dropdown
            name="role"
            values={root.roles}
            label={root.roleLabel}
            value={parsedValue}
            onChange={formik.handleChange}
          />
          <Box mt={3} display="flex" flexDirection="column">
            <Box>
              <MainButton type="submit" color="primary" title={root.mainButtonLabel} />
              <MainButton
                title={root.cancelButtonLabel}
                color="inherit"
                type="button"
                onClick={handleCancel}
                style={{ marginLeft: '1em' }}
              />
            </Box>
            <Box mt={3}>
              <MainButton
                type="button"
                color="secondary"
                variant="contained"
                title={isUserEnabled ? root.disable : root.enable}
                onClick={() =>
                  handleAccountState({
                    uri: isUserEnabled
                      ? GET_ADMIN_USER_DISABLE_URI(userId as string)
                      : GET_ADMIN_USER_ENABLE_URI(userId as string),
                    action: EnumHTTPActions.PUT,
                  })
                }
              />
            </Box>
          </Box>
        </form>
      </section>
    </main>
  )
}

export default EditUser
