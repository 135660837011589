import React, { memo } from 'react'
import { Box } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { useStore } from '@hooks'
import { useMutation } from 'react-query'
import { useAlert } from 'react-alert'
import { shallow } from 'zustand/shallow'
import configs from '@configs'
import { MainButton } from '@components'
import { removeErrorPreffix } from '@utils'
import classes from './index.module.scss'

const Upload = () => {
  const {
    components: {
      inputs: { Upload: root },
    },
  } = configs
  const customizedAlert = useAlert()
  const [dataSet, uploadCSV] = useStore(
    (state) => [
      // state.blueprint[state.tabs.currentCategoryTab]?.[state.tabs.subCurrentTab as DataSetTypes],
      state.tabs.currentCategoryTab &&
      state.tabs.subCurrentTab &&
      state.tabs.currentCategoryTab !== 'Home'
        ? state.blueprint[state.tabs.currentCategoryTab]?.[state.tabs.subCurrentTab]
        : null,
      state.uploadCSV,
    ],
    shallow
  )
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    maxFiles: 1,
    accept: {
      // '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
      'text/*': ['.csv', '.x-csv'],
      'application/*': ['.vnd.ms-excel', '.csv', '.x-csv'],
    },
  })
  const isFileSet = acceptedFiles.length > 0
  const {
    mutate,
    isIdle,
    error: isError,
  } = useMutation(uploadCSV, {
    onSuccess: () => {
      customizedAlert.success(root.uploadSuccess)
    },
    onError: (error: Error) => {
      customizedAlert.error(removeErrorPreffix(error.message))
      acceptedFiles.splice(0, acceptedFiles.length)
    },
  })
  const shouldUpload = isFileSet && (isIdle || isError)

  if (shouldUpload && dataSet) {
    mutate({ file: acceptedFiles[0], id: dataSet.id })
  }

  return (
    <Box className={classes.container}>
      <Box {...getRootProps({ className: `dropzone ${classes.dropzone} ` })}>
        <input {...getInputProps()} />
        <p>{isFileSet ? root.uploadingCsv(acceptedFiles[0]) : root.dragCsv}</p>
        {!isFileSet && (
          <MainButton
            title={root.uploadCsv}
            variant="outlined"
            color="inherit"
            style={{ marginLeft: '0.5em' }}
          />
        )}
      </Box>
    </Box>
  )
}

export default memo(Upload)
