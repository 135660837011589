import { NamedSet } from 'zustand/middleware'
import { GetState } from 'zustand'
import { GlobalState } from '..'
import createServiceSlice, { UserServices } from './services'
import createValueSlice, { UserValues } from './values'

const createSlice = (set: NamedSet<GlobalState>, get: GetState<GlobalState>) => ({
  ...createServiceSlice(set, get),
  ...createValueSlice(),
})

export default createSlice
export type { UserServices, UserValues }
