import { Blueprint, BlueprintTabs } from '@interfaces'

export interface BlueprintValues {
  blueprint: BlueprintTabs
  blueprints: Blueprint[]
}

export const NEW_BLUEPRINT_STATE: BlueprintTabs = { Home: {} }
const createSlice = (): BlueprintValues => ({
  blueprint: NEW_BLUEPRINT_STATE,
  blueprints: [] as Blueprint[],
})

export default createSlice
