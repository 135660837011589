import { GetState } from 'zustand'
import { NamedSet } from 'zustand/middleware'
import { GlobalState } from '..'
import { GET_ACCOUNTS_URI, GET_ACCOUNT_URI } from '@uris'
import { generateHeaders, validateResponse, generateHeadersWithOnlyAuth } from '@utils'
import {
  EnumHTTPActions,
  AccountsResponse,
  IAccount,
  EnumResponseTypes,
  NewAccountRequestParams,
  BaseAccountRequest,
  Pagination,
} from '@interfaces'

export interface AccountServices {
  getAccounts: (args: Pagination) => Promise<AccountsResponse>
  getAccount: (id?: string | number) => Promise<IAccount>
  editAccount: (arg: BaseAccountRequest) => Promise<void>
  newAccount: (arg: NewAccountRequestParams) => Promise<void>
}

const createSlice = (set: NamedSet<GlobalState>, get: GetState<GlobalState>): AccountServices => ({
  getAccounts: async (args) => {
    const res = await fetch(GET_ACCOUNTS_URI(args.page, args.matching), {
      method: EnumHTTPActions.GET,
      headers: generateHeaders(get().token),
    })
    try {
      const accounts = (await validateResponse(res, set)) as AccountsResponse
      return accounts
    } catch (error) {
      throw new Error(error as string)
    }
  },
  getAccount: async (id) => {
    const res = await fetch(GET_ACCOUNT_URI(id), {
      method: EnumHTTPActions.GET,
      headers: generateHeaders(get().token),
    })
    try {
      const account = (await validateResponse(res, set)) as IAccount
      return account
    } catch (error) {
      throw new Error(error as string)
    }
  },

  editAccount: async ({ id, name, type, expiresAt }) => {
    const formdata = new FormData()
    const parsedType = type.toLowerCase()
    formdata.append('account[name]', name)
    formdata.append('account[account_type]', parsedType)
    if (expiresAt) {
      formdata.append('account[expires_at]', expiresAt)
    }
    const res = await fetch(GET_ACCOUNT_URI(id) as string, {
      method: EnumHTTPActions.PUT,
      headers: generateHeadersWithOnlyAuth(get().token),
      body: formdata,
    })
    try {
      const status = await validateResponse(res, set, EnumResponseTypes.STATUS)
      return status
    } catch (error) {
      throw new Error(error as string)
    }
  },
  newAccount: async ({ id, name, type, userId, userName, expiresAt }) => {
    var account = JSON.stringify({
      account: {
        name: name,
        mavenlink_account_id: id,
        account_type: type,
        expires_at: expiresAt,
        users_attributes: [
          {
            name: userName,
            mavenlink_user_id: userId,
          },
        ],
      },
    })
    const res = await fetch(GET_ACCOUNTS_URI(), {
      method: EnumHTTPActions.POST,
      body: account,
      headers: generateHeaders(get().token),
    })
    try {
      const status = await validateResponse(res, set, EnumResponseTypes.STATUS)
      return status
    } catch (error) {
      throw new Error(error as string)
    }
  },
})

export default createSlice
