import { TooltipProps, Tooltip, styled, tooltipClasses } from '@mui/material'
import configs from '@configs'
const {
  configs: {
    colors: { CHARCOAL },
  },
} = configs
const StyledToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 140,
    padding: 8,
    textAlign: 'center',
    fontSize: 12,
    backgroundColor: CHARCOAL,
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      backgroundColor: CHARCOAL,
    },
  },
})

export default StyledToolTip
