import React, { memo } from 'react'
import { Alert, AlertTitle } from '@mui/material'
import { AlertTemplateProps } from 'react-alert'
import { capitalizeFirstLetter } from '@utils'

interface IProps extends Omit<AlertTemplateProps, 'style'> {
  style?: React.CSSProperties
  id?: string
}

const AlertMemoized = ({ options, message, style }: IProps) => {
  return (
    <Alert
      style={{ color: 'white', minWidth: '15vw', width: '20vw', ...style }}
      severity={options.type}
      variant="filled"
    >
      <AlertTitle sx={{ fontWeight: 'bold' }}>
        {capitalizeFirstLetter(options.type as string)}
      </AlertTitle>
      {message}
    </Alert>
  )
}

export default memo(AlertMemoized)
