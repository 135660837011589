import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { ReactComponent as Logo } from '@assets/Kantata-K-Orange-Box.svg'
import { PREAUTH_URI, MAVENLINK_AUTH_URI } from '@uris'
import configs from '@configs'
import { MainButton, Title } from '@components'

const Login = () => {
  const {
    pages: {
      Login: { buttonText, header },
    },
    configs: { preAuth: PRE_AUTH },
  } = configs
  const [disable, setDisable] = useState(false)
  const preAuthTokenInput = useRef<HTMLInputElement>(null)
  useEffect(() => {
    fetch(PREAUTH_URI(), { method: 'POST' })
      .then((response) => response.json())
      .then((preauth_token) => {
        if (preAuthTokenInput.current) {
          preAuthTokenInput.current.value = preauth_token.preauth_token
        }
        var token = preauth_token.preauth_token
        var storage = window.localStorage
        storage.setItem(PRE_AUTH, token)
      })
  }, [])

  return (
    <Box display="flex" height="100%" justifyContent="center" alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        paddingX={10}
        gap={1}
        paddingY={5}
        sx={{
          backgroundColor: (theme) => theme.palette.grey[400],
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: (theme) => {
            const borderRadius = `${theme.shape.borderRadius}px`
            return borderRadius
          },
        }}
      >
        <Box display="flex" justifyContent="center">
          <Logo width={100} />
        </Box>
        <Title header={header} variant="h5" fontWeight="bolder" />
        <form
          onSubmit={() => setDisable(true)}
          method="post"
          action={MAVENLINK_AUTH_URI()}
          style={{ alignItems: 'stretch' }}
        >
          <input ref={preAuthTokenInput} type="hidden" name="preauth_token" />
          <MainButton
            disabled={disable}
            color="newPrimary"
            title={buttonText}
            variant="contained"
            type="submit"
          />
        </form>
      </Box>
    </Box>
  )
}

export default Login
