import { GlobalState } from '../App/store'
import { NamedSet } from 'zustand/middleware'
import {
  ResponseTypes,
  EnumResponseTypes,
  Blueprint,
  BlueprintTabs,
  BlobTypes,
  EnumBlobExtensions,
  BlobExtensions,
  CategoryDataSets,
} from '../App/interfaces'
import configs from '../App/configs'

const {
  configs: { auth: AUTH, auth: PRE_AUTH },
} = configs

export const DEV_MODE = process.env.NODE_ENV !== 'production'

export const getBlobExtension = (blobType: BlobTypes): BlobExtensions => {
  switch (blobType) {
    // Add more cases as need we only count for .csv;
    default:
      return EnumBlobExtensions.CSV
  }
}
export const removeErrorPreffix = (str: string) =>
  str.includes('Error:') ? str.split('Error:').pop()?.trim() : str
export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
export const logout = () => {
  localStorage.removeItem(AUTH)
  localStorage.removeItem(PRE_AUTH)
}
export const downloadBlob = (blob: Blob, name: string) => {
  let a = document.createElement('a')
  let blobURL = URL.createObjectURL(blob)
  a.download = `${name}.${getBlobExtension(blob.type as BlobTypes)}`
  a.href = blobURL
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const validateResponse = async (
  res: Response,
  set: NamedSet<GlobalState>,
  type?: ResponseTypes
) => {
  let response
  if (res.status > 300) {
    response = await res.json()
    const { error, errors } = response
    switch (res.status) {
      case 404:
        set((state) => ({ ...state, notFound: true }))
        break
      case 401:
        logout()
        set((state) => ({ ...state, token: null }))
        break
      default:
        break
    }
    throw new Error(error || errors)
  } else {
    switch (type) {
      case EnumResponseTypes.BLOB:
        response = await res.blob()
        break
      case EnumResponseTypes.STATUS:
        response = res.status
        break
      default:
        response = await res.json()
        break
    }
    return response
  }
}
export const transformBlueprintToSets = (blueprint: Blueprint): BlueprintTabs => {
  const initialSet: BlueprintTabs = {
    Home: {
      id: String(blueprint.id),
      name: blueprint.name,
    },
  }

  const tabs = blueprint.data_sets.reduce((accum, val) => {
    val.categories.forEach((category) => {
      accum[category] = {
        ...accum[category],
        [val.display_name]: val,
      } as CategoryDataSets
    })
    return accum
  }, initialSet)

  return tabs
}
export const generateHeaders = (token?: string | null): Headers => {
  return new Headers({
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  })
}
export const generateHeadersWithOnlyAuth = (token?: string | null): Headers => {
  return new Headers({
    Authorization: `Bearer ${token}`,
  })
}

export const generateUTCDate = (date: Date | string) => {
  let dateObject = new Date(date)
  const dateSplit = dateObject?.toISOString().split('T')[0].split('-')
  const year = parseInt(dateSplit[0])
  const month = parseInt(dateSplit[1]) - 1
  const day = parseInt(dateSplit[2])
  return new Date(Date.UTC(year, month, day, 12)).toISOString()
}

export const addDaysToDate = (days: number, defaultDate = new Date()) => {
  return new Date(defaultDate.setDate(defaultDate.getDate() + days))
}

/**

Formats date to this specific format
@arg date: date value
@arg formatter?: Optional desired output format (month,days,seconds,minutes etc.)
@output  Sep 2, 2022, 11:08:51 AM
**/
export const formatDate = (
  date: number | string | Date,
  formatter?: Intl.DateTimeFormatOptions
) => {
  return new Date(date).toLocaleString(
    'en-US',
    formatter
      ? formatter
      : {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }
  )
}
