import React, { useState, useMemo, useRef } from 'react'
import debounce from 'lodash.debounce'
import { FormControl, useMediaQuery, useTheme } from '@mui/material'
import { Box, CircularProgress } from '@mui/material'
import { MainButton, Text, Title, Table } from '@components'
import { useStore } from '@hooks'
import { useQuery } from 'react-query'
import { GET_ACCOUNTS_URI } from '@uris'
import { useNavigate } from 'react-router-dom'
import routes from '@routes'
import configs from '@configs'
import { formatDate } from '@utils'
import { IAccount } from '@interfaces'

const accountSelectors = [
  'name',
  'mavenlink_account_id',
  'account_type',
  'created_at',
  'updated_at',
  'expires_at',
  'state',
]

function AdminContent() {
  const {
    core: { dateFormatterWithoutSeconds, defaultPlaceHolder },
    pages: { Admins: root },
    configs: {
      debounceSearchTime,
      cache: { TABLE_CONFIG_FETCH },
    },
  } = configs
  const isMdWidth = useMediaQuery(useTheme().breakpoints.up('md'))
  const accountRef = useRef<HTMLInputElement>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(routes.admins.newAdmin.navigate())
  }
  const getAccounts = useStore((state) => state.getAccounts)
  const { data, isLoading } = useQuery(
    [`${GET_ACCOUNTS_URI(page, searchTerm)}`],
    () => getAccounts({ page, matching: searchTerm }),
    {
      ...TABLE_CONFIG_FETCH,
      staleTime: 0,
      select: (response) => {
        return {
          ...response,
          accounts: response.accounts.map((account) => {
            return {
              ...account,
              created_at: formatDate(account.created_at, dateFormatterWithoutSeconds),
              updated_at: formatDate(account.updated_at, dateFormatterWithoutSeconds),
              expires_at: account.expires_at
                ? formatDate(account.expires_at, dateFormatterWithoutSeconds)
                : defaultPlaceHolder,
            }
          }),
        }
      },
    }
  )

  const handleSearch = debounce(() => {
    setSearchTerm(accountRef.current?.value || '')
  }, debounceSearchTime)

  const parsedHeaders = useMemo(() => {
    return root.gridHeaders.map((header) => ({ data: header }))
  }, [])

  const parsedData = useMemo(() => {
    return data?.accounts.map((account) => {
      return {
        id: account.id,
        data: accountSelectors.map((header) => ({ value: account[header as keyof IAccount] })),
        action: () => navigate(routes.admins.editAdmin.navigate(account.id)),
      }
    })
  }, [data?.accounts])
  return (
    <main>
      <section>
        <Box mb={5}>
          <Title variant="h5" header={root.provisionAccountTitle} />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          mb={2}
          flexDirection={isMdWidth ? 'row' : 'column'}
        >
          <FormControl
            sx={{
              width: (theme) => ({
                lg: theme.breakpoints.values.sm,
              }),
            }}
          >
            <Text
              onChange={handleSearch}
              name="account"
              label={root.searchPlaceholder}
              outlined
              ref={accountRef}
              leftIcon="SearchIcon"
            />
          </FormControl>

          <MainButton
            style={{ marginTop: isMdWidth ? 0 : '.5em' }}
            title={root.provisionAccountButtonText}
            type="submit"
            color="primary"
            onClick={handleClick}
          />
        </Box>
        <Box>
          {isLoading ? (
            <CircularProgress />
          ) : (
            data &&
            parsedData && (
              <Table
                headers={parsedHeaders}
                page={data.meta.page}
                data={parsedData}
                setPage={setPage}
                totalPages={data.meta.total_pages}
                boldedHeaders
              />
            )
          )}
        </Box>
      </section>
    </main>
  )
}
export default AdminContent
