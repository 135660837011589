import React, { memo } from 'react'
import { Typography, TypographyProps } from '@mui/material'
interface IProps extends TypographyProps {
  header: string
}

const Header = ({ header, ...otherProps }: IProps) => {
  return <Typography {...otherProps}>{header}</Typography>
}

export default memo(Header)
