import React from 'react'
import { useParams } from 'react-router-dom'
import { useStore } from '@hooks'
import { useQuery, useMutation } from 'react-query'
import { GET_USER_URI, GET_USER_ENABLE_URI, GET_USER_DISABLE_URI, GET_PROFILE_URI } from '@uris'
import { useNavigate } from 'react-router-dom'
import { EnumAccountUserStates, EnumHTTPActions } from '@interfaces'
import { Text, MainButton, Title } from '@components'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/material'
import { useAlert } from 'react-alert'
import { shallow } from 'zustand/shallow'
import routes from '@routes'
import configs from '@configs'
import { removeErrorPreffix } from '@utils'

type IParams = {
  id: string
}
function UserContentEdit() {
  const {
    configs: {
      cache: { LONG_CACHE },
    },
    pages: {
      Users: { Edit: root },
    },
  } = configs
  const editUpdateAlert = useAlert()
  const navigate = useNavigate()
  const { id } = useParams<IParams>()
  const handleCancel = () => {
    navigate(routes.users.navigate())
  }
  const [mutation, getUser, getProfile] = useStore(
    (state) => [state.mutation, state.getUser, state.getProfile],
    shallow
  )
  const { data, isLoading } = useQuery([`${GET_USER_URI(id as string)}`], () =>
    getUser(id as string)
  )
  const { data: profile } = useQuery([GET_PROFILE_URI()], () => getProfile(), {
    ...LONG_CACHE,
  })

  const { mutate: enableDisableUser } = useMutation(mutation, {
    onSuccess: () => {
      handleCancel()
    },
    onError: (error: Error) => {
      editUpdateAlert.error(removeErrorPreffix(error.message))
    },
  })

  const isDisableEnabled = data?.state === EnumAccountUserStates.ACTIVE
  const shouldShowEnableDisableButton = id !== String(profile?.id)

  return (
    <main>
      <section>
        <Title variant="h5" header={root.editUserTitle} />
        {isLoading || !data ? (
          <CircularProgress />
        ) : (
          id && (
            <form>
              <Text
                name="id"
                value={data.mavenlink_user_id}
                label={root.userIdBoxLabel}
                outlined
                gutterBottom
                disable
              />
              <Text
                name="name"
                value={data.name}
                label={root.userNameBoxLabel}
                outlined
                gutterBottom
                disable
              />
              <Box display="flex" gap={(theme) => theme.spacing(2)}>
                {shouldShowEnableDisableButton && (
                  <MainButton
                    type="button"
                    title={
                      isDisableEnabled ? root.disableUserButtonText : root.enableUserButtonText
                    }
                    color="secondary"
                    variant="contained"
                    onClick={() =>
                      enableDisableUser({
                        uri: isDisableEnabled ? GET_USER_DISABLE_URI(id) : GET_USER_ENABLE_URI(id),
                        action: EnumHTTPActions.PUT,
                      })
                    }
                  />
                )}
                <MainButton
                  title={root.cancelButtonText}
                  type="button"
                  color="inherit"
                  onClick={handleCancel}
                />
              </Box>
            </form>
          )
        )}
      </section>
    </main>
  )
}
export default UserContentEdit
