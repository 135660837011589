import { memo, ReactNode } from 'react'
import { Box, SxProps, useTheme, Theme } from '@mui/material'
import Title from '../Title'

interface IProps {
  title: string
  children?: ReactNode
  sxChildrenBoxProps?: SxProps<Theme>
}

const Card = ({ title, children, sxChildrenBoxProps }: IProps) => {
  const contextTheme = useTheme()

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={40}
        padding={0.5}
        sx={{
          backgroundColor: (theme) => theme.palette.newPrimary.main,
          borderRadius: (theme) => {
            const borderRadius = `${theme.shape.borderRadius}px`
            return `${borderRadius} ${borderRadius} 0 0`
          },
        }}
      >
        <Title
          header={title}
          color={contextTheme.palette.grey[400]}
          textAlign="center"
          variant="body1"
          fontWeight={contextTheme.typography.fontWeightBolder}
        />
      </Box>
      <Box
        minHeight={110}
        height="100%"
        sx={{
          backgroundColor: (theme) => theme.palette.grey[400],
          border: (theme) => `1px solid ${theme.palette.newPrimary.main}`,
          borderRadius: (theme) => {
            const borderRadius = `${theme.shape.borderRadius}px`
            return `0 0 ${borderRadius} ${borderRadius}`
          },
          ...sxChildrenBoxProps,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
export default memo(Card)
