import { GetState } from 'zustand'
import { NamedSet } from 'zustand/middleware'
import { GlobalState } from '..'
import createServiceSlice, { AppServices } from './services'
import createValueSlice, { AppValues } from './values'

const createSlice = (set: NamedSet<GlobalState>, get: GetState<GlobalState>) => ({
  ...createValueSlice(),
  ...createServiceSlice(set, get),
})

export default createSlice
export type { AppServices, AppValues }
