import { NamedSet } from 'zustand/middleware'
import { GetState } from 'zustand'
import { GlobalState } from '..'
import { MutationCommonParams, EnumHTTPActions, EnumResponseTypes } from '@interfaces'
import { generateHeaders, validateResponse } from '@utils'
export interface AppServices {
  setNotFound: (opt: boolean) => void
  mutation: (arg: MutationCommonParams) => Promise<void>
}

const createSlice = (set: NamedSet<GlobalState>, get: GetState<GlobalState>): AppServices => ({
  setNotFound: (opt) => set((state) => ({ ...state, notFound: opt }), false, 'setNotFound'),
  mutation: async ({ uri, action }) => {
    const res = await fetch(uri, {
      method: action || EnumHTTPActions.POST,
      headers: generateHeaders(get().token),
    })
    try {
      await validateResponse(res, set, EnumResponseTypes.STATUS)
      return
    } catch (e) {
      throw new Error(e as string)
    }
  },
})

export default createSlice
