import React, { useState, memo } from 'react'
import { shallow } from 'zustand/shallow'
import { useAlert } from 'react-alert'
import classes from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { Avatar, ButtonBase, Menu, MenuItem, Box } from '@mui/material'
import { Typography } from '@mui/material'
import { useStore } from '@hooks'
import { logout } from '@utils'
import routes from '@routes'
import configs from '@configs'
interface IProps {
  imgSrc: string
  isTrial?: boolean
  showAdminPanel?: boolean
  showAccountSettings?: boolean
}

const Header = ({
  imgSrc,
  showAdminPanel = false,
  showAccountSettings = false,
  isTrial = false,
}: IProps) => {
  const {
    layouts: { Header: root },
  } = configs
  const navigate = useNavigate()
  const customizedAlert = useAlert()
  const [notFound, setAuthToken, setNotFound] = useStore(
    (state) => [state.notFound, state.setAuthToken, state.setNotFound],
    shallow
  )
  const [menu, setMenu] = useState<null | Element>(null)
  const handleClick = (event: React.MouseEvent) => {
    setMenu(event.currentTarget)
  }
  const handleClose = () => {
    setMenu(null)
  }

  const handleAdmin = () => {
    if (notFound) setNotFound(false)
    navigate(routes.admins.navigate())
    handleClose()
  }

  const handleUser = () => {
    if (notFound) setNotFound(false)
    navigate(routes.users.navigate())
    handleClose()
  }
  const handleLogout = () => {
    customizedAlert.info(root.successLogut)
    logout()
    setAuthToken(null)
  }

  return (
    <Box
      className={classes.header}
      justifyContent={isTrial ? 'space-between' : 'flex-end'}
      component="header"
    >
      {isTrial && (
        <Box display="flex" alignItems="center" ml={2}>
          <Typography variant="body2">{root.trialMessage}</Typography>
        </Box>
      )}
      <ButtonBase onClick={handleClick}>
        <Avatar src={imgSrc} className={classes.avatar}>
          {imgSrc && imgSrc.charAt(0)}
        </Avatar>
      </ButtonBase>
      <Menu
        id="profile-menu"
        anchorEl={menu}
        keepMounted
        open={Boolean(menu)}
        onClose={handleClose}
        elevation={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {showAccountSettings && <MenuItem onClick={handleUser}>{root.accoutSettings}</MenuItem>}
        {showAdminPanel && <MenuItem onClick={handleAdmin}> {root.adminPanel}</MenuItem>}
        <MenuItem onClick={handleLogout}>{root.logOut}</MenuItem>
      </Menu>
    </Box>
  )
}

export default memo(Header)
