import React, { memo, useMemo } from 'react'
import { Tabs, Tab } from '@mui/material'
import {
  UploadStates,
  ValidateStates,
  KrushStates,
  EnumUploadStates,
  EnumValidateStates,
  EnumKrushStates,
  SetStatus,
} from '@interfaces'
import { Typography, Box, TabScrollButton } from '@mui/material'
import {
  UploadFileRounded,
  Pending,
  InsertDriveFile,
  Plagiarism,
  Task,
  Cancel,
} from '@mui/icons-material'
import configs from '@configs'

const {
  configs: {
    colors: { GRAY_04, SUCCESS_02, WARNING, PRIMARY_02, PRIMARY, BLACK_01 },
    home: HOME,
  },
} = configs

interface IProps {
  options: string[]
  activeTab: string
  setActiveTab: (arg: string) => void
  optionsStates?: Omit<SetStatus, 'Home'>
  defaultStyle?: boolean
}

const getIcon = (state?: UploadStates | ValidateStates | KrushStates) => {
  const defaultStyle = {
    style: { fill: BLACK_01 },
  }
  switch (state) {
    case EnumKrushStates.UPLOADING:
    case EnumValidateStates.UPLOADING:
    case EnumUploadStates.UPLOADING:
      return <Pending {...defaultStyle} />
    case EnumUploadStates.UPLOADED:
      return <InsertDriveFile style={{ fill: SUCCESS_02 }} />
    case EnumValidateStates.ERROR:
    case EnumValidateStates.UPLOADED:
      return (
        <Plagiarism
          style={{ fill: state === EnumValidateStates.UPLOADED ? SUCCESS_02 : WARNING }}
        />
      )
    case EnumKrushStates.ERROR:
    case EnumKrushStates.UPLOADED:
      return <Task style={{ fill: state === EnumKrushStates.UPLOADED ? SUCCESS_02 : WARNING }} />
    case EnumKrushStates.CANCELLED:
      return <Cancel {...defaultStyle} />
    default:
      return <UploadFileRounded {...defaultStyle} />
  }
}

const commonTabStyle = {
  width: 'auto',
  minWidth: 'auto',
}

const MemoizedTabs = ({
  activeTab,
  options,
  optionsStates,
  setActiveTab,
  defaultStyle = true,
}: IProps) => {
  const indexTab = useMemo(() => {
    return options.indexOf(activeTab)
  }, [activeTab, options])
  return (
    <Tabs
      value={indexTab}
      style={{
        maxWidth: defaultStyle ? '80vw' : '100%',
        backgroundColor: defaultStyle ? 'none' : GRAY_04,
      }}
      ScrollButtonComponent={TabScrollButton}
      TabIndicatorProps={{
        style: { backgroundColor: GRAY_04, zIndex: -1, height: '10em' },
      }}
      variant="scrollable"
      onChange={(event: React.ChangeEvent<{}>, value: number) => {
        setActiveTab(options[value])
      }}
    >
      {options.map((label, index) => {
        const isActiveTab = index === indexTab
        return (
          <Tab
            key={label}
            label={
              <Box component="span" display="flex">
                {label !== HOME &&
                  getIcon(optionsStates && optionsStates[label as keyof Omit<SetStatus, 'Home'>])}
                <Typography
                  style={{
                    textTransform: 'none',
                    marginLeft: '0.25em',
                    fontWeight: isActiveTab ? 'bolder' : 'normal',
                  }}
                >
                  {label}
                </Typography>
              </Box>
            }
            style={
              defaultStyle
                ? { ...commonTabStyle, color: isActiveTab ? PRIMARY : 'gray' }
                : {
                    ...commonTabStyle,
                    color: 'black',
                    backgroundColor: isActiveTab ? PRIMARY_02 : 'inherit',
                    whiteSpace: 'nowrap',
                  }
            }
          />
        )
      })}
    </Tabs>
  )
}

export default memo(MemoizedTabs)
