import { GlobalState } from '..'
import { NamedSet } from 'zustand/middleware'
import { GetState } from 'zustand'
import configs from '@configs'
import {
  MavenLinkRecordsType,
  IBlueprintPayloadRequest,
  Blueprint,
  EnumHTTPActions,
  BlueprintsResponse,
  BlueprintBaseRequestParams,
  Pagination,
} from '@interfaces'
import { generateHeaders, validateResponse, transformBlueprintToSets } from '@utils'
import { GET_BLUEPRINTS_URI, GET_BLUEPRINT_URI } from '@uris'
import { NEW_BLUEPRINT_STATE } from './values'

const {
  configs: { home: HOME },
} = configs
export interface BlueprintServices {
  createBlueprint: (params: BlueprintBaseRequestParams) => Promise<Blueprint>
  resetBlueprint: () => void
  getBlueprints: (
    shouldReturnResponse?: Boolean,
    filter?: Pagination
  ) => Promise<void | BlueprintsResponse>
  getBlueprint: (id: string | number, persistTabs?: boolean) => Promise<Blueprint>
  setBlueprint: (arg: Blueprint) => void
}

const createSlice = (
  set: NamedSet<GlobalState>,
  get: GetState<GlobalState>
): BlueprintServices => ({
  createBlueprint: async ({ options, name }) => {
    const dataSets = options.reduce((accumulator: MavenLinkRecordsType[], current, index) => {
      accumulator[index] = { mavenlink_record_type: current.name } as MavenLinkRecordsType
      return accumulator
    }, [])

    const newBlueprint = {
      blueprint: { name, data_sets_attributes: dataSets } as IBlueprintPayloadRequest,
    }
    const res = await fetch(GET_BLUEPRINTS_URI(), {
      method: EnumHTTPActions.POST,
      body: JSON.stringify(newBlueprint),
      headers: generateHeaders(get().token),
    })
    try {
      const blueprint = (await validateResponse(res, set)) as Blueprint
      set(
        (state) => ({
          ...state,
          tabs: { ...get().tabs, blueprintCategories: [HOME, ...blueprint.categories] },
          blueprints: [blueprint, ...state.blueprints],
          blueprint: transformBlueprintToSets(blueprint),
        }),
        false,
        'createBlueprint'
      )
      return blueprint
    } catch (e) {
      throw new Error(e as string)
    }
  },
  resetBlueprint: () =>
    set(
      (state) => ({
        ...state,
        blueprint: NEW_BLUEPRINT_STATE,
        tabs: {
          ...get().tabs,
          blueprintCategories: [HOME],
          currentCategoryTab: HOME,
          subCurrentTab: null,
        },
        notFound: false,
      }),
      false,
      'resetBlueprint'
    ),
  getBlueprints: async (shouldReturnResponse, pagination?: Pagination) => {
    const res = await fetch(GET_BLUEPRINTS_URI(pagination), {
      method: EnumHTTPActions.GET,
      headers: generateHeaders(get().token),
    })
    try {
      const json = (await validateResponse(res, set)) as BlueprintsResponse
      if (shouldReturnResponse) {
        return json
      } else {
        set(
          (state) => ({
            ...state,
            blueprints: json.blueprints,
          }),
          false,
          'getBlueprints'
        )
        return
      }
    } catch (e) {
      throw new Error(e as string)
    }
  },
  getBlueprint: async (id: string | number, persistTabs = true) => {
    const res = await fetch(GET_BLUEPRINT_URI(id) as string, {
      method: EnumHTTPActions.GET,
      headers: generateHeaders(get().token),
    })
    try {
      const blueprint = (await validateResponse(res, set)) as Blueprint
      set(
        (state) => ({
          ...state,
          blueprint: transformBlueprintToSets(blueprint),
          notFound: false,
          tabs: persistTabs
            ? { ...get().tabs, blueprintCategories: [HOME, ...blueprint.categories] }
            : {
                subCurrentTab: null,
                currentCategoryTab: HOME,
                blueprintCategories: [HOME, ...blueprint.categories],
              },
        }),
        false,
        'getBlueprint'
      )
      return blueprint
    } catch (e) {
      throw new Error(e as string)
    }
  },
  setBlueprint: (blueprint) =>
    set(
      (state) => ({
        ...state,
        blueprint: transformBlueprintToSets(blueprint),
        tabs: {
          subCurrentTab: null,
          currentCategoryTab: HOME,
          blueprintCategories: [HOME, ...blueprint.categories],
        },
      }),
      false,
      'setBlueprint'
    ),
})

export default createSlice
