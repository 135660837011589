import { GetState } from 'zustand'
import { NamedSet } from 'zustand/middleware'
import { GlobalState } from '..'
import createServiceSlice, { DatasetServices } from './services'

const createSlice = (set: NamedSet<GlobalState>, get: GetState<GlobalState>) => ({
  ...createServiceSlice(set, get),
})

export default createSlice
export type { DatasetServices }
