import configs from '@configs'
const {
  configs: { auth: AUTH },
} = configs

export interface UserValues {
  token?: string | null
}

const createSlice = (): UserValues => ({
  token: localStorage.getItem(AUTH),
})

export default createSlice
