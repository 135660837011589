import React, { memo } from 'react'
import { CircularProgress, Box, Typography, BoxProps } from '@mui/material'

interface IProps {
  progress: number
  outerBoxProps?: BoxProps['sx']
}

const Progress = ({ progress, outerBoxProps }: IProps) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', ...outerBoxProps }}>
      <CircularProgress variant="determinate" value={progress} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
          progress
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

export default memo(Progress)
