import React, { memo } from 'react'
import { Box, InputLabel, FormControl, Select, SelectChangeEvent } from '@mui/material'
import { MenuItem } from '@mui/material'
interface IProps {
  name: string
  value?: string
  values: string[]
  label: string
  onChange?: (e: SelectChangeEvent<string>) => void
}

const Dropdown = ({ name, value, label, onChange, values }: IProps) => {
  return (
    <Box sx={{ minWidth: 205 }}>
      <FormControl fullWidth>
        <InputLabel id="select-label">{label}</InputLabel>
        <Select name={name} labelId="select-label" value={value} label={label} onChange={onChange}>
          {values?.map((field) => (
            <MenuItem value={field} key={field}>
              {field}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
export default memo(Dropdown)
