import React from 'react'
import routes from './pages/routes'
import { Routes, BrowserRouter, Route } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Alert } from '@components'
import { Provider as AlertProvider, positions } from 'react-alert'
import { useStore } from './hooks'
import configs from '@configs'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    fontWeightBolder: number
  }

  interface TypographyVariantsOptions {
    fontWeightBolder: number
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    fontWeightBolder: true
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    newPrimary: SimplePaletteColorOptions
  }

  interface PaletteOptions {
    newPrimary: SimplePaletteColorOptions
  }
  interface CommonColors {
    charcoal: string
    paper: string
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    newPrimary: true
  }
}

const {
  configs: {
    colors: {
      PRIMARY,
      SECONDARY,
      WHITE,
      NEW_PRIMARY,
      PRIMARY_02,
      DARK_BLUE,
      SUCCESS,
      SUCCESS_02,
      WARNING,
      DANGER,
      GRAY_01,
      GRAY_02,
      GRAY_03,
      GRAY_04,
      GRAY_05,
      CHARCOAL,
      PRIMARY_PAPER,
      BLACK_01,
    },
  },
} = configs
const queryClient = new QueryClient()
const alertOptions = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '5px',
}

export const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    body1: {
      fontWeight: 600,
    },
    fontWeightBolder: 750,
  },
  shape: {
    borderRadius: 6,
  },
  palette: {
    primary: {
      main: PRIMARY,
      light: PRIMARY_02,
      dark: DARK_BLUE,
    },
    secondary: {
      main: SECONDARY,
    },
    newPrimary: {
      main: NEW_PRIMARY,
      contrastText: WHITE,
    },
    success: {
      main: SUCCESS,
      light: SUCCESS_02,
    },
    warning: {
      main: WARNING,
    },
    error: {
      main: DANGER,
    },
    grey: {
      100: GRAY_01,
      200: GRAY_02,
      300: GRAY_03,
      400: GRAY_04,
      500: GRAY_05,
      900: GRAY_05,
    },
    common: {
      black: BLACK_01,
      white: WHITE,
      charcoal: CHARCOAL,
      paper: PRIMARY_PAPER,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&$disabled': {
            color: WHITE,
            fontWeight: 'normal',
            opacity: 0.6,
          },
        },
        outlinedInherit: {
          color: GRAY_01,
        },
        textPrimary: {
          backgroundColor: PRIMARY,
          color: WHITE,
          '&:hover': {
            backgroundColor: PRIMARY,
            opacity: 0.8,
          },
        },
      },
    },
  },
})

function App() {
  const authToken = useStore((state) => state.token)
  const setAuthToken = useStore((state) => state.setAuthToken)

  /* COMMENT THIS SCOPE IF YOU WANT TO  VALIDATE REQUEST 401 AND VALIDATE LOGOUT ON LOCAL */
  if (process.env.NODE_ENV === 'development') {
    const { REACT_APP_DEV_TOKEN } = process.env
    setAuthToken(REACT_APP_DEV_TOKEN)
  }
  /*                   */
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AlertProvider containerStyle={{ marginTop: '3em' }} template={Alert} {...alertOptions}>
          <QueryClientProvider client={queryClient}>
            {authToken ? (
              <BrowserRouter>
                <Routes>
                  <Route path={routes.main.path} element={<routes.main.component />} />
                </Routes>
                <ReactQueryDevtools initialIsOpen={false} />
              </BrowserRouter>
            ) : (
              <BrowserRouter>
                <Routes>
                  <Route path={routes.callback.path} element={<routes.callback.component />} />
                  <Route path={routes.login.path} element={<routes.login.component />} />
                </Routes>
              </BrowserRouter>
            )}
          </QueryClientProvider>
        </AlertProvider>
      </ThemeProvider>
    </div>
  )
}
export default App
