import { MetaBaseParams } from '.'
export interface BaseAccount {
  id: number
  account_type: Accounts
  created_at: string
  updated_at: string
  name: string
  url: string
  role: Roles
  state: AccountStates
  expires_at?: string
}

export interface IAccount extends BaseAccount {
  mavenlink_account_id: number
  email_notification_suppression: boolean
}

export interface AccountsResponse {
  meta: MetaBaseParams
  accounts: IAccount[]
}

export type Accounts = 'client' | 'internal' | 'trial' | 'migration'
export type AccountStates = 'active' | 'disabled'
export enum EnumAccountStates {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}
export enum EnumAccounts {
  TRIAL = 'trial',
  CLIENT = 'client',
  INTERNAL = 'internal',
  MIGRATION = 'migration',
}

export type Roles = 'view_only' | 'employee_admin' | 'customer'
export enum EnumRoles {
  VIEW_ONLY = 'view_only',
  ADMIN = 'employee_admin',
  CUSTOMER = 'customer',
}

export interface BaseAccountRequest {
  id: string | number
  name: string
  type: Accounts
  expiresAt?: string
}
export interface NewAccountRequestParams extends BaseAccountRequest {
  userId: string
  userName: string
}
