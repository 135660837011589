import React, { memo } from 'react'
import {
  TableRow,
  Pagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableFooter,
  Paper,
  Box,
  SxProps,
} from '@mui/material'
import { ArrowUpward, ArrowDownward } from '@mui/icons-material'

enum HeaderIcons {
  SORT = 'arrowSorting',
}

interface IData {
  value?: string | boolean | number | null | undefined
  state?: JSX.Element | boolean
  shouldLink?: boolean
  sx?: SxProps
  action?: (arg: IRow) => void
}
interface IRow {
  data: IData[]
  id: number | string
  action?: (arg: IRow) => void
}
interface IHeader {
  data: string
  sx?: SxProps
  action?: (headerKey: string) => void
  sorted?: boolean
  icons?: HeaderIcons
  key?: string
}
interface IProps {
  headers: IHeader[]
  data: IRow[]
  page: number
  setPage: (arg: number) => void
  totalPages: number
  boldedHeaders?: boolean
  boldedRows?: boolean
}

// FN: TO ADD MORE ICONS BASED PAIRS.
const getSorting = (headerIcons: HeaderIcons, sorted = false) => {
  switch (headerIcons) {
    default:
      const commonStyles = {
        width: '.7em',
      }
      return sorted ? (
        <>
          <ArrowUpward style={{ ...commonStyles, marginTop: '.2em' }} />
          <ArrowDownward style={{ ...commonStyles }} />
        </>
      ) : (
        <>
          <ArrowDownward style={{ ...commonStyles, marginTop: '.2em' }} />
          <ArrowUpward style={{ ...commonStyles }} />
        </>
      )
  }
}

const MemoizedTable = ({
  headers,
  data,
  page,
  setPage,
  totalPages,
  boldedHeaders,
  boldedRows,
}: IProps) => {
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  return (
    <Paper
      sx={{
        overflow: 'hidden',
        padding: '0 1em 1em 1em',
        backgroundColor: (theme) => theme.palette.common.paper,
      }}
    >
      <TableContainer>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'auto' }}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    key={header.key || header.data}
                    onClick={() => header.action?.(header.key || header.data)}
                    align="left"
                    sx={{
                      backgroundColor: (theme) => theme.palette.common.paper,
                      color: (theme) => theme.palette.grey[100],
                      fontSize: 12,
                      width: 'auto',
                      cursor: header.action ? 'pointer' : 'inherit',
                      fontWeight: (theme) =>
                        boldedHeaders
                          ? theme.typography.fontWeightBold
                          : theme.typography.fontWeightRegular,
                      fontFamily: "'Open Sans', sans-serif",
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Box sx={{ ...header.sx }}>{header.data}</Box>
                      {header.action && header.icons && (
                        <Box display="flex">{getSorting(header.icons, header.sorted)}</Box>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: (theme) => theme.palette.common.white }}>
              {data.map((row) => {
                return (
                  <TableRow
                    onClick={() => row.action?.(row)}
                    hover
                    role="checkbox"
                    sx={{
                      whiteSpace: 'nowrap',
                    }}
                    tabIndex={-1}
                    key={`Row${row.id}`}
                  >
                    {row.data.map((field, index) => {
                      return (
                        <TableCell
                          sx={{
                            fontFamily: "'Open Sans', sans-serif",
                            fontWeight: (theme) =>
                              boldedRows
                                ? theme.typography.fontWeightBold
                                : theme.typography.fontWeightRegular,
                            cursor: field.shouldLink ? 'pointer' : 'inherit',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                          key={`${field.value}-${row.id}-${index}`}
                          onClick={() => field.action?.(row)}
                        >
                          {
                            <Box display="flex" padding={0} sx={{ ...field.sx }}>
                              {field.state && (
                                <Box padding={0} mr={1} mt=".2em">
                                  {field.state}
                                </Box>
                              )}
                              <Box
                                sx={{
                                  color: (theme) =>
                                    field.shouldLink ? theme.palette.primary.main : 'inherit',
                                }}
                                alignSelf="center"
                              >
                                {field.value}
                              </Box>
                            </Box>
                          }
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: (theme) => theme.palette.common.paper,
            alignItems: 'center',
            marginTop: '1em',
          }}
          component="div"
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            shape="rounded"
            color="primary"
            showFirstButton
            showLastButton
          />
        </TableFooter>
      </TableContainer>
    </Paper>
  )
}

export default memo(MemoizedTable)
export { HeaderIcons }
