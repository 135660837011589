import { GetState } from 'zustand'
import { NamedSet } from 'zustand/middleware'
import { GlobalState } from '..'
import {
  EnumHTTPActions,
  IUser,
  UsersResponse,
  EnumResponseTypes,
  EditUserRequestParams,
  NewUserRequestParams,
  Pagination,
  NewAdminUserRequest,
  EditAdminUserRequest,
} from '@interfaces'
import { generateHeaders, validateResponse, generateHeadersWithOnlyAuth } from '@utils'
import {
  GET_PROFILE_URI,
  GET_USER_URI,
  GET_USERS_URI,
  GET_MAVENLINK_USERS_URI,
  GET_ADMIN_USERS_URI,
  GET_ADMIN_USER_URI,
} from '@uris'
import configs from '@configs'

const {
  configs: { auth: AUTH },
} = configs

export interface UserServices {
  getProfile: () => Promise<IUser>
  getUser: (id: string | number) => Promise<IUser>
  getUsers: (page: number) => Promise<UsersResponse>
  getMavenlinkUsers: (name?: string) => Promise<UsersResponse>
  getAdminUsers: (accountId: string, pagination: Pagination) => Promise<UsersResponse>
  createAdminUser: (payload: NewAdminUserRequest) => Promise<void>
  getAdminUser: (accId: string | number, userId: string | number) => Promise<IUser>
  editAdminUser: (payload: EditAdminUserRequest) => Promise<void>
  newUser: (newUser: NewUserRequestParams) => Promise<void>
  editUser: (user: EditUserRequestParams) => Promise<void>
  setAuthToken: (token?: string | null) => void
}

const createSlice = (set: NamedSet<GlobalState>, get: GetState<GlobalState>): UserServices => ({
  getProfile: async () => {
    const res = await fetch(GET_PROFILE_URI(), {
      method: EnumHTTPActions.GET,
      headers: generateHeaders(get().token),
    })
    try {
      const account = (await validateResponse(res, set)) as IUser
      return account
    } catch (e) {
      throw new Error(e as string)
    }
  },
  getUser: async (id: string | number) => {
    const res = await fetch(GET_USER_URI(id), {
      method: EnumHTTPActions.GET,
      headers: generateHeaders(get().token),
    })
    try {
      const status = (await validateResponse(res, set)) as IUser
      return status
    } catch (error) {
      throw new Error(error as string)
    }
  },
  getUsers: async (page: number) => {
    const res = await fetch(GET_USERS_URI(page), {
      method: EnumHTTPActions.GET,
      headers: generateHeaders(get().token),
    })
    try {
      const data = (await validateResponse(res, set)) as UsersResponse
      return data
    } catch (error) {
      throw new Error(error as string)
    }
  },
  getMavenlinkUsers: async (name?: string): Promise<UsersResponse> => {
    const res = await fetch(GET_MAVENLINK_USERS_URI(name), {
      method: EnumHTTPActions.GET,
      headers: generateHeaders(get().token),
    })
    try {
      const data = (await validateResponse(res, set)) as UsersResponse
      return data
    } catch (error) {
      throw new Error(error as string)
    }
  },
  getAdminUsers: async (accountId, pagination): Promise<UsersResponse> => {
    const res = await fetch(GET_ADMIN_USERS_URI(accountId, pagination), {
      method: EnumHTTPActions.GET,
      headers: generateHeaders(get().token),
    })
    try {
      const data = (await validateResponse(res, set)) as UsersResponse
      return data
    } catch (error) {
      throw new Error(error as string)
    }
  },
  createAdminUser: async ({ role = 'customer', accId, name, mavenlinkUserId }) => {
    const body = JSON.stringify({
      user: {
        name,
        role,
        account_id: accId,
        mavenlink_user_id: mavenlinkUserId,
      },
    })
    const res = await fetch(GET_ADMIN_USERS_URI(), {
      method: EnumHTTPActions.POST,
      body,
      headers: generateHeaders(get().token),
    })
    try {
      const status = await validateResponse(res, set, EnumResponseTypes.STATUS)
      return status
    } catch (error) {
      throw new Error(error as string)
    }
  },
  getAdminUser: async (accId, userId): Promise<IUser> => {
    const res = await fetch(GET_ADMIN_USER_URI(accId, userId), {
      method: EnumHTTPActions.GET,
      headers: generateHeaders(get().token),
    })
    try {
      const user = (await validateResponse(res, set)) as IUser
      return user
    } catch (error) {
      throw new Error(error as string)
    }
  },
  editAdminUser: async ({ name, mavenlinkUserId: mavenlink_user_id, role, accId, userId }) => {
    const body = JSON.stringify({
      user: {
        name,
        mavenlink_user_id,
        role,
      },
    })
    const res = await fetch(GET_ADMIN_USER_URI(accId, userId), {
      method: EnumHTTPActions.PUT,
      body,
      headers: generateHeaders(get().token),
    })
    try {
      const status = await validateResponse(res, set, EnumResponseTypes.STATUS)
      return status
    } catch (error) {
      throw new Error(error as string)
    }
  },
  newUser: async ({ id, name, accId }) => {
    const form = new FormData()
    form.append('user[name]', name)
    form.append('user[mavenlink_user_id]', String(id))
    form.append('user[account_id]', accId.toString())
    const res = await fetch(GET_USERS_URI() as string, {
      method: EnumHTTPActions.POST,
      body: form,
      headers: generateHeadersWithOnlyAuth(get().token),
    })
    try {
      const status = await validateResponse(res, set, EnumResponseTypes.STATUS)
      return status
    } catch (error) {
      throw new Error(error as string)
    }
  },
  editUser: async ({ id, name }) => {
    var formdata = new FormData()
    formdata.append('user[name]', name)
    const res = await fetch(GET_USER_URI(id) as string, {
      method: EnumHTTPActions.PUT,
      headers: generateHeadersWithOnlyAuth(get().token),
      body: formdata,
    })
    try {
      const status = await validateResponse(res, set, EnumResponseTypes.STATUS)
      return status
    } catch (error) {
      throw new Error(error as string)
    }
  },
  setAuthToken: (token?: string | null) => {
    if (token) {
      localStorage.setItem(AUTH, token)
    }

    set((state) => ({ ...state, token: token }), false, 'setAuthToken')
  },
})

export default createSlice
