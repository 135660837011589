import React, { memo } from 'react'
import { Button, ButtonProps } from '@mui/material'
interface IProps extends ButtonProps {
  title: string
}

const MainButton = ({ title, color = 'primary', ...props }: IProps) => {
  return (
    <Button
      {...props}
      sx={{
        textTransform: 'none',
        fontWeight: (theme) => theme.typography.fontWeightBold,
      }}
      color={color}
    >
      {title}
    </Button>
  )
}

export default memo(MainButton)
