import createBlueprintSlice, { BlueprintServices, BlueprintValues } from './blueprint'
import createUserSlice, { UserServices, UserValues } from './user'
import createTabsSlice, { TabsServices, TabsValues } from './tabs'
import createAccountSlice, { AccountServices } from './account'
import createDatasetSlice, { DatasetServices } from './dataset'
import createAppSlice, { AppServices, AppValues } from './app'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export type GlobalState = BlueprintServices &
  BlueprintValues &
  UserServices &
  UserValues &
  TabsServices &
  TabsValues &
  AccountServices &
  DatasetServices &
  AppServices &
  AppValues

const useStore = create<GlobalState, [['zustand/devtools', never]]>(
  devtools(
    (set, get) => ({
      ...createBlueprintSlice(set, get),
      ...createUserSlice(set, get),
      ...createTabsSlice(set, get),
      ...createAccountSlice(set, get),
      ...createDatasetSlice(set, get),
      ...createAppSlice(set, get),
    }),
    { enabled: process.env.NODE_ENV !== 'test' }
  )
)

export default useStore
