import { DialogActions } from '@mui/material'
import { Dialog, DialogProps, DialogTitle, DialogContent } from '@mui/material'
interface IProps extends DialogProps {
  title?: string
  onClose: () => void
  cancelButton?: React.ReactNode
  confirmButton?: React.ReactNode
}
const StyledModal = ({ onClose, open, title, children, cancelButton, confirmButton }: IProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={{ style: { backgroundColor: 'rgba(0,0,0,0.1)' } }}
      PaperProps={{
        style: { boxShadow: 'none', width: '50vw', maxWidth: 500, minWidth: 250, maxHeight: 400 },
      }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {confirmButton}
        {cancelButton}
      </DialogActions>
    </Dialog>
  )
}

export default StyledModal
