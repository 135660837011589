import React, { useRef, useState } from 'react'
import { Text, MainButton, Title } from '@components'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { useStore } from '@hooks'
import { Box } from '@mui/material'
import { useAlert } from 'react-alert'
import debounce from 'lodash.debounce'
import { CircularProgress } from '@mui/material'
import { shallow } from 'zustand/shallow'
import { GET_ACCOUNT_URI, GET_MAVENLINK_USERS_URI } from '@uris'
import routes from '@routes'
import configs from '@configs'
import { removeErrorPreffix } from '@utils'

function UserContentNew() {
  const {
    pages: {
      Users: { New: root },
    },
    configs: {
      debounceSearchTime,
      cache: { TABLE_CONFIG_FETCH, LONG_CACHE },
    },
  } = configs
  const newUserAlert = useAlert()
  const emptyUserAlert = useAlert()
  const dropdownUserAlert = useAlert()
  const [name, setName] = useState<string>('')
  const [newUser, getAccount, getMavenlinkUsers] = useStore(
    (state) => [state.newUser, state.getAccount, state.getMavenlinkUsers],
    shallow
  )
  const navigate = useNavigate()
  const handleCancel = () => {
    navigate(routes.users.navigate())
  }

  const { mutate: provisionNewUser } = useMutation(newUser, {
    onSuccess: () => {
      navigate(routes.users.navigate())
    },
    onError: (error: Error) => {
      newUserAlert.error(removeErrorPreffix(error.message))
    },
  })

  const userRef = useRef<HTMLInputElement>()

  const { data: parsedUsersResponse } = useQuery(
    [`${GET_MAVENLINK_USERS_URI(name)}`],
    () => getMavenlinkUsers(name),
    {
      enabled: name.length > 0,
      refetchOnWindowFocus: false,
      select: ({ users }) => {
        return {
          users,
          names: users.map((user) => user.full_name).filter((user) => user) as string[],
        }
      },
      ...LONG_CACHE,
    }
  )

  const { data: account, isLoading } = useQuery([`${GET_ACCOUNT_URI()}`], () => getAccount(), {
    ...TABLE_CONFIG_FETCH,
  })

  const debounceSearch = debounce(() => {
    if (userRef.current && userRef.current.value.length >= 3) {
      setName(userRef.current?.value || '')
    }
  }, debounceSearchTime)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!userRef.current?.value) {
      emptyUserAlert.error(removeErrorPreffix(root.emptyMavenLinkUserErrorMessage))
    } else if (parsedUsersResponse && userRef.current && account) {
      var users = parsedUsersResponse.users
      if (users && userRef.current?.value) {
        var userId = users.find((user) => userRef.current?.value === user.full_name)?.id
        if (!userId) {
          dropdownUserAlert.error(removeErrorPreffix(root.selectFromDropdownErrorMessage))
        } else {
          provisionNewUser({
            id: String(userId),
            name: userRef.current?.value,
            accId: String(account.id),
          })
        }
      }
    }
  }

  return (
    <main>
      <section>
        <Title variant="h5" header={root.newUserTitle} />
        {isLoading || !account ? (
          <CircularProgress />
        ) : (
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Text
              name="id"
              label={root.mavenlinkUserBoxLabel}
              outlined
              gutterBottom
              leftIcon="SearchIcon"
              helperText={root.helperText}
              ref={userRef}
              onChange={debounceSearch}
              autoCompleteOptions={parsedUsersResponse?.names || []}
            />
            <Box mt={2}>
              <MainButton title={root.provisionUserButtonText} type="submit" color="primary" />
              <MainButton
                title={root.cancelButtonText}
                type="button"
                color="inherit"
                onClick={handleCancel}
                style={{ marginLeft: '1em' }}
              />
            </Box>
          </form>
        )}
      </section>
    </main>
  )
}
export default UserContentNew
