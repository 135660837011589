import React, { useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import classes from './index.module.scss'
import { Navbar, Header } from '@layouts'
import routes from '@routes'
import { useStore } from '@hooks'
import { Routes, Route } from 'react-router-dom'
import { useQuery } from 'react-query'
import { shallow } from 'zustand/shallow'
import { GET_BLUEPRINTS_URI, GET_PROFILE_URI } from '@uris'
import { EnumAccounts, EnumMavenlinkPermisions, EnumRoles } from '@interfaces'
import { useAlert } from 'react-alert'
import { logout } from '@utils'
import configs from '@configs'

const Main = () => {
  const {
    core: { viewOnly },
    configs: {
      cache: { LONG_CACHE, ALWAYS_CACHE },
    },
  } = configs
  const customizedAlert = useAlert()
  const [blueprints, getBlueprints, notFound, getProfile, setAuthToken] = useStore(
    (state) => [
      state.blueprints,
      state.getBlueprints,
      state.notFound,
      state.getProfile,
      state.setAuthToken,
    ],
    shallow
  )
  useQuery([GET_BLUEPRINTS_URI()], () => getBlueprints(), {
    ...ALWAYS_CACHE,
  })
  const { data: account, isLoading } = useQuery([GET_PROFILE_URI()], () => getProfile(), {
    ...LONG_CACHE,
  })

  const [showAdmin, showAccountSettings, isTrial] = [
    account?.role === EnumRoles.ADMIN,
    account?.mavenlink_permissions === EnumMavenlinkPermisions.OWNER ||
      account?.mavenlink_permissions === EnumMavenlinkPermisions.ADMIN,
    account?.account_type === EnumAccounts.TRIAL,
  ]

  useEffect(() => {
    if (account && account.role === EnumRoles.VIEW_ONLY) {
      logout()
      setAuthToken(null)
      customizedAlert.info(viewOnly)
    }
  }, [account, setAuthToken, customizedAlert])

  return (
    <div className={classes['wrapper-main']}>
      {isLoading || !account ? (
        <CircularProgress />
      ) : (
        <>
          <Navbar blueprints={blueprints} />
          <div className={classes['wrapper-content']}>
            <Header
              isTrial={isTrial}
              imgSrc={account.photo_path || account.name}
              showAdminPanel={showAdmin}
              showAccountSettings={showAccountSettings}
            />
            {notFound ? (
              <routes.notFound.component />
            ) : (
              <Routes>
                {/* ADD MORE ROUTES FOR CONTENT TO BE DISPLAY UNDER MAIN  element*/}
                <Route path={routes.home.path} element={<routes.home.component />} />
                <Route
                  path={routes.blueprint.newBlueprint.path}
                  element={<routes.blueprint.newBlueprint.component />}
                />
                {showAccountSettings && (
                  <>
                    <Route
                      path={routes.users.editUser.path}
                      element={<routes.users.editUser.component />}
                    />
                    <Route
                      path={routes.users.newUser.path}
                      element={<routes.users.newUser.component />}
                    />
                    <Route
                      path={routes.users.path}
                      element={<routes.users.component user={account} />}
                    />
                  </>
                )}
                {showAdmin && (
                  <>
                    <Route
                      path={routes.admins.editAdmin.path}
                      element={<routes.admins.editAdmin.component />}
                    />
                    <Route
                      path={routes.admins.newAdmin.path}
                      element={<routes.admins.newAdmin.component />}
                    />
                    <Route path={routes.admins.path} element={<routes.admins.component />} />
                    <Route
                      path={routes.admins.users.newUser.path}
                      element={<routes.admins.users.newUser.component />}
                    />
                    <Route
                      path={routes.admins.users.editUser.path}
                      element={<routes.admins.users.editUser.component />}
                    />
                  </>
                )}
                <Route path={routes.blueprint.path} element={<routes.blueprint.component />} />
                <Route path={routes.notFound.path} element={<routes.notFound.component />} />
              </Routes>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Main
