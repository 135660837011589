import React, { useMemo, useState, useRef } from 'react'
import debounce from 'lodash.debounce'
import { MainButton, Text, Title, Dropdown, DatePicker, Table } from '@components'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from '@hooks'
import { useQuery, useMutation } from 'react-query'
import {
  GET_ACCOUNT_ENABLE_URI,
  GET_ACCOUNT_URI,
  GET_ACCOUNT_DISABLE_URI,
  GET_ADMIN_USERS_URI,
} from '@uris'
import { generateUTCDate, addDaysToDate, formatDate, removeErrorPreffix } from '@utils'
import {
  Accounts,
  EnumAccounts,
  EnumAccountUserStates,
  EnumHTTPActions,
  EnumRoles,
  IUser,
} from '@interfaces'
import { useFormik } from 'formik'
import { Box, FormControl } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { useAlert } from 'react-alert'
import { shallow } from 'zustand/shallow'
import routes from '@routes'
import configs from '@configs'

type IParams = {
  id: string
}

const userSelectors = ['name', 'mavenlink_user_id', 'role', 'created_at', 'updated_at', 'state']
function AdminContentEdit() {
  const {
    configs: {
      cache: { TABLE_CONFIG_FETCH },
      debounceSearchTime,
      validationSchemas,
    },
    core: { defaultMinDays, trialMaxDays, migrationMaxDays, dateFormatterWithoutSeconds },
    pages: {
      Admins: { Edit: root, Users: subRoot },
    },
  } = configs
  const defaultDate = useMemo(() => {
    return addDaysToDate(defaultMinDays)
  }, [])
  const [page, setPage] = useState<number>(1)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const userRef = useRef<HTMLInputElement>(null)
  const editUpdateAccountAlert = useAlert()
  const navigate = useNavigate()
  const { id } = useParams<IParams>()
  const [mutation, editAccount, getAccount, getUsers] = useStore(
    (state) => [state.mutation, state.editAccount, state.getAccount, state.getAdminUsers],
    shallow
  )
  const { data, isLoading } = useQuery([`${GET_ACCOUNT_URI(id)}`], () => getAccount(id))
  const handleCancel = () => {
    navigate(routes.admins.navigate())
  }
  const isAdminEnabled = data?.state === EnumAccountUserStates.ACTIVE

  const formik = useFormik({
    initialValues: {
      id: data?.mavenlink_account_id,
      name: data?.name,
      type: data?.account_type as Accounts,
      expiresAt: data?.expires_at || defaultDate,
      minDate: data?.expires_at,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values.name && id) {
        handleSubmit({
          id,
          name: values.name,
          type: values.type,
          expiresAt:
            (isTrial || isMigration) && values.expiresAt
              ? generateUTCDate(values.expiresAt)
              : undefined,
        })
      }
    },
    validationSchema: validationSchemas.admin.edit,
  })

  const { mutate: handleAccountState } = useMutation(mutation, {
    onSuccess: () => {
      navigate(routes.admins.navigate())
    },
    onError: (error: Error) => {
      editUpdateAccountAlert.error(removeErrorPreffix(error.message.replace(',', ', ')))
    },
  })

  const { mutate: handleSubmit } = useMutation(editAccount, {
    onSuccess: () => {
      navigate(routes.admins.navigate())
    },
    onError: (error: Error) => {
      editUpdateAccountAlert.error(removeErrorPreffix(error.message))
    },
  })

  const parsedValue = useMemo(() => {
    if (formik.values.type && !root.accountTypesArray.includes(formik.values.type)) {
      const parsedAccountType =
        formik.values.type.charAt(0).toUpperCase() + formik.values.type.slice(1)
      return parsedAccountType
    } else {
      return formik.values.type
    }
  }, [formik.values.type])

  const { data: fetchedUsers, isLoading: isLoadingUsers } = useQuery(
    [GET_ADMIN_USERS_URI(id as string, { matching: searchTerm, page })],
    () => getUsers(id as string, { matching: searchTerm, page }),
    {
      ...TABLE_CONFIG_FETCH,
      staleTime: 0,
      enabled: Boolean(id),
      select: (usersResponse) => {
        return {
          ...usersResponse,
          users: usersResponse.users.map((user) => ({
            ...user,
            updated_at: user.updated_at
              ? formatDate(user.updated_at, dateFormatterWithoutSeconds)
              : user.updated_at,
            created_at: user.created_at
              ? formatDate(user.created_at, dateFormatterWithoutSeconds)
              : user.created_at,
            role: user.role === EnumRoles.CUSTOMER ? subRoot.roles[0] : subRoot.roles[1],
          })),
        }
      },
    }
  )

  const parsedData = useMemo(() => {
    return fetchedUsers?.users.map((fetchedUser) => {
      return {
        id: fetchedUser.id,
        data: userSelectors.map((header) => ({ value: fetchedUser[header as keyof IUser] })),
        action: () => navigate(routes.admins.users.editUser.navigate(id as string, fetchedUser.id)),
      }
    })
  }, [fetchedUsers?.users])

  const parsedHeaders = useMemo(() => {
    return root.Users.userGridHeaders.map((header) => ({ data: header }))
  }, [])

  const [trialsMaxDaysTimeStamp, defaultMinDaysTimeStamp, migrationMaxDaysTimeStamp] =
    useMemo((): number[] => {
      return [
        addDaysToDate(trialMaxDays).getTime(),
        defaultDate.getTime(),
        addDaysToDate(migrationMaxDays).getTime(),
      ]
    }, [defaultDate])

  const handleSearch = debounce(() => {
    setSearchTerm(userRef.current?.value || '')
  }, debounceSearchTime)

  const handleAddUser = () => {
    if (id) navigate(routes.admins.users.newUser.navigate(id))
  }

  const accountType = parsedValue && parsedValue.toLowerCase()
  const isTrial = accountType === EnumAccounts.TRIAL
  const isMigration = accountType === EnumAccounts.MIGRATION

  return (
    <main>
      <section>
        <Title variant="h5" header={root.editAccountTitle} />
        {isLoading || !data || !parsedValue ? (
          <CircularProgress />
        ) : (
          id && (
            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
              <Text
                name="id"
                value={formik.values.id}
                disable={true}
                label={root.accountIdTextBoxTitle}
                outlined
                gutterBottom
                onChange={formik.handleChange}
              />
              <Text
                name="name"
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                label={(formik.touched.name && formik.errors.name) || root.accountNameTextBoxTitle}
                outlined
                gutterBottom
                focused
                onChange={formik.handleChange}
              />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box sx={{ marginRight: 1 }}>
                  <Dropdown
                    name="type"
                    value={parsedValue}
                    label={root.accountTypeTextBoxTitle}
                    values={root.accountTypesArray}
                    onChange={formik.handleChange}
                  />
                </Box>
                {(isMigration || isTrial) && (
                  <Box display="flex" justifyContent="flex-end">
                    <DatePicker
                      value={formik.values.expiresAt}
                      onChange={(date) => formik.setFieldValue('expiresAt', date)}
                      minDate={
                        !formik.values.minDate ? defaultMinDaysTimeStamp : formik.values.minDate
                      }
                      maxDate={isTrial ? trialsMaxDaysTimeStamp : migrationMaxDaysTimeStamp}
                    />
                  </Box>
                )}
              </Box>
              <Box mt={3}>
                <MainButton title={root.saveButtonText} type="submit" color="primary" />
                <MainButton
                  title={root.cancelButtonText}
                  type="button"
                  color="inherit"
                  style={{ marginLeft: '1em' }}
                  onClick={handleCancel}
                />
              </Box>
              <Box mt={3}>
                <MainButton
                  title={
                    isAdminEnabled ? root.disableAccountButtonText : root.enableAccountButtonText
                  }
                  type="button"
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    handleAccountState({
                      uri: isAdminEnabled
                        ? GET_ACCOUNT_DISABLE_URI(id)
                        : GET_ACCOUNT_ENABLE_URI(id),
                      action: EnumHTTPActions.PUT,
                    })
                  }
                />
              </Box>
            </form>
          )
        )}
        <Box mt={5}>
          <Title header={root.Users.gridTitle} variant="h6" />
          <Box display="flex" justifyContent="space-between" mt={3} mb={4}>
            <FormControl
              sx={{
                width: (theme) => ({
                  sm: theme.breakpoints.values.sm,
                }),
              }}
            >
              <Text
                outlined
                name="user"
                leftIcon="SearchIcon"
                ref={userRef}
                onChange={handleSearch}
                placeholder={root.Users.inputPlaceholder}
              />
            </FormControl>
            <MainButton
              title={root.Users.mainButtonLabel}
              onClick={handleAddUser}
              color="primary"
            />
          </Box>
          {isLoadingUsers || !fetchedUsers || !parsedData ? (
            <CircularProgress />
          ) : (
            <Table
              headers={parsedHeaders}
              page={page}
              setPage={setPage}
              totalPages={fetchedUsers.meta.total_pages}
              data={parsedData}
            />
          )}
        </Box>
      </section>
    </main>
  )
}
export default AdminContentEdit
