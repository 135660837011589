import { useMemo, useRef, useState } from 'react'
import { Box, CircularProgress, FormControl, SxProps } from '@mui/material'
import { Title, Text, Table, HeaderIcons } from '@components'
import configs from '@configs'
import debounce from 'lodash.debounce'
import { useQuery } from 'react-query'
import { GET_BLUEPRINTS_URI } from '@uris'
import { useStore } from '@hooks'
import { shallow } from 'zustand/shallow'
import { useNavigate } from 'react-router-dom'
import routes from '@routes'
import {
  EnumKrushStates,
  EnumSort,
  EnumUploadStates,
  EnumValidateStates,
  MavenlinkDataSetStates,
  Pagination,
} from '@interfaces'
import { CheckCircle, InsertDriveFile, ReportProblem } from '@mui/icons-material'
import { formatDate } from '@utils'

const {
  core: { dateFormatterWithoutSeconds: dateFormatter, defaultPlaceHolder },
  pages: {
    Home: { header, label, tableHeaders },
  },
  pages: {
    Blueprint: {
      Dataset: { prettifyOnlineStatus },
    },
  },
  configs: {
    debounceSearchTime,
    cache: { TABLE_CONFIG_FETCH },
    colors,
  },
} = configs

const getBlueprintIcon = (state: MavenlinkDataSetStates) => {
  switch (state) {
    case EnumValidateStates.UPLOADING:
    case EnumKrushStates.UPLOADING:
    case EnumUploadStates.UPLOADING:
      return <CircularProgress size="1.5rem" />

    case EnumValidateStates.UPLOADED:
    case EnumKrushStates.UPLOADED:
    case EnumUploadStates.UPLOADED:
      return <CheckCircle style={{ fill: colors.SUCCESS }} />

    case EnumKrushStates.ERROR:
    case EnumValidateStates.ERROR:
      return <ReportProblem style={{ fill: colors.WARNING }} />

    case EnumKrushStates.CANCELLED:
      return <ReportProblem style={{ fill: colors.GRAY_01 }} />
    default:
      return <InsertDriveFile style={{ fill: colors.GRAY_03 }} />
  }
}

const HomePage = () => {
  const navigate = useNavigate()
  const [getBlueprints] = useStore((state) => [state.getBlueprints], shallow)
  const [page, setPage] = useState<number>(1)
  const [currentHeader, setCurrentHeader] = useState<string>('')
  const blueprintRef = useRef<HTMLInputElement>()
  const [searchTerm, setSearchTerm] = useState<string>('')

  const handleSearch = debounce(() => {
    setSearchTerm(blueprintRef.current?.value || '')
  }, debounceSearchTime)

  // Since we gave a key we lookup by key
  const handleSort = (headerKey: string) => {
    setCurrentHeader(headerKey)
    setHeaders(
      headers.map((headerValue) => {
        if (headerValue.key === headerKey) {
          headerValue.sorted = !headerValue.sorted
        } else {
          headerValue.sorted = false
        }
        return headerValue
      })
    )
  }

  const [headers, setHeaders] = useState(
    tableHeaders.map((head) => ({
      sorted: false,
      icons: HeaderIcons.SORT,
      action: handleSort,
      data: head.value,
      key: head.key,
      sx:
        head.key === 'data_sets_count'
          ? ({ whiteSpace: 'break-space', width: 'min-content' } as SxProps)
          : undefined,
    }))
  )

  const filter = useMemo<Pagination>(() => {
    return {
      matching: searchTerm,
      amount: 10,
      page,
      sort: headers
        .filter((val) => {
          return val.key === currentHeader
        })
        .reduce((accum, val) => {
          return {
            ...accum,
            [val.key]: val.sorted ? EnumSort.ASC : EnumSort.DESC,
          }
        }, {}),
    }
  }, [headers, searchTerm, page, currentHeader])

  const { data: blueprints } = useQuery(
    [GET_BLUEPRINTS_URI(filter)],
    () => getBlueprints(true, filter),
    {
      ...TABLE_CONFIG_FETCH,
      staleTime: 0,
      select: (response) => {
        if (response) {
          return {
            ...response,
            data: response.blueprints.map((blueprint) => ({
              id: blueprint.id,
              data: [
                {
                  value: blueprint.name,
                  shouldLink: true,
                  action: () =>
                    navigate(routes.blueprint.navigate(blueprint.id), {
                      state: { persistTabs: false },
                    }),
                },
                {
                  value: prettifyOnlineStatus(blueprint.status),
                  state: getBlueprintIcon(blueprint.status),
                },
                { value: blueprint.user_name },
                { value: formatDate(blueprint.created_at, dateFormatter) },
                {
                  value: blueprint.processed_at
                    ? formatDate(blueprint.processed_at, dateFormatter)
                    : defaultPlaceHolder,
                },
                { value: formatDate(blueprint.updated_at, dateFormatter) },
                { value: blueprint.data_sets_count, sx: { marginLeft: '1.5em' } as SxProps },
              ],
            })),
          }
        }
        return
      },
    }
  )

  return (
    <main>
      <section>
        <Box mb={5}>
          <Title header={header} variant="h5" gutterBottom noWrap />
        </Box>
        <FormControl
          sx={{
            width: (theme) => ({
              sm: theme.breakpoints.values.sm,
            }),
          }}
        >
          <Text
            onChange={handleSearch}
            name="blueprint"
            label={label}
            outlined
            gutterBottom
            ref={blueprintRef}
            leftIcon="SearchIcon"
          />
        </FormControl>
        {!blueprints ? (
          <CircularProgress />
        ) : (
          <Table
            headers={headers}
            data={blueprints.data}
            page={page}
            setPage={setPage}
            totalPages={blueprints.meta.total_pages}
            boldedHeaders
            boldedRows
          />
        )}
      </section>
    </main>
  )
}

export default HomePage
