import { Box } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import routes from '@routes'
import { Text, MainButton, Title } from '@components'
import configs from '@configs'
import { useFormik } from 'formik'
import { useStore } from '@hooks'
import { shallow } from 'zustand/shallow'
import { useMutation } from 'react-query'
import { useAlert } from 'react-alert'
import { removeErrorPreffix } from '../../../../../utils'

const AdminUserNew = () => {
  const {
    configs: { validationSchemas },
    pages: {
      Admins: {
        Users: { New: root },
      },
    },
  } = configs
  const customizedAlert = useAlert()
  const navigate = useNavigate()
  const { id } = useParams()
  const [createAdminUser] = useStore((state) => [state.createAdminUser], shallow)
  const { mutate } = useMutation(createAdminUser, {
    onSuccess: () => {
      if (id) navigate(routes.admins.editAdmin.navigate(id))
    },
    onError: (error: Error) => {
      customizedAlert.error(removeErrorPreffix(error.message.replace(',', ', ')))
    },
  })
  const formik = useFormik({
    initialValues: {
      name: '',
      mavenlinkUserId: '',
    },
    onSubmit: (values) => {
      if (values && id) {
        mutate({ ...values, accId: id })
      }
    },
    validationSchema: validationSchemas.admin.user,
  })
  const handleCancel = () => {
    if (id) navigate(routes.admins.editAdmin.navigate(id))
  }
  return (
    <main>
      <section>
        <Title header={root.title} variant="h5" />
        <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
          <Text
            value={formik.values.name}
            onChange={formik.handleChange}
            name="name"
            gutterBottom
            outlined
            error={formik.touched.name && Boolean(formik.errors.name)}
            label={(formik.touched.name && formik.errors.name) || root.nameLabel}
          />
          <Text
            value={formik.values.mavenlinkUserId}
            onChange={formik.handleChange}
            name="mavenlinkUserId"
            gutterBottom
            outlined
            error={formik.touched.mavenlinkUserId && Boolean(formik.errors.mavenlinkUserId)}
            label={
              (formik.touched.mavenlinkUserId && formik.errors.mavenlinkUserId) ||
              root.mavelinkIdLabel
            }
          />
          <Box mt={3}>
            <MainButton type="submit" color="primary" title={root.mainButtonLabel} />
            <MainButton
              title={root.cancelButtonLabel}
              color="inherit"
              type="button"
              onClick={handleCancel}
              style={{ marginLeft: '1em' }}
            />
          </Box>
        </form>
      </section>
    </main>
  )
}

export default AdminUserNew
