import { BaseAccount, MetaBaseParams, Roles } from '.'

export interface IUser extends BaseAccount {
  mavenlink_user_id: number
  account_id: number
  full_name?: string
  photo_path?: string
  mavenlink_permissions: MavenlinkPermissionsTypes | null
}

export interface UsersResponse {
  meta: MetaBaseParams
  users: IUser[]
}

export enum EnumAccountUserStates {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export interface NewUserRequestParams {
  id: string
  name: string
  accId: string
}

export interface NewAdminUserRequest extends Omit<NewUserRequestParams, 'id'> {
  mavenlinkUserId: string
  role?: Roles
}

export interface EditAdminUserRequest extends NewAdminUserRequest {
  userId: string
}

export type EditUserRequestParams = Omit<NewUserRequestParams, 'accId'>
export type MavenlinkPermissionsTypes =
  | 'administrator'
  | 'owner'
  | 'reports_viewer_with_cost'
  | 'report_viewer'
  | 'project_lead'
  | 'project_creator'
  | 'no_permissions'

export enum EnumMavenlinkPermisions {
  ADMIN = 'administrator',
  OWNER = 'owner',
  REPORTS_VIEWER_WITH_COST = 'reports_viewer_with_cost',
  REPORT_VIEWER = 'report_viewer',
  PROJECT_LEAD = 'project_lead',
  PROJECT_CREATOR = 'project_creator',
  NO_PERMISSIONS = 'no_permissions',
}
