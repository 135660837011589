import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.scss'

/** HotFix to use HTTPS only */
if (window.location.protocol !== 'https:') {
  window.location.protocol = 'https'
} else {
  ReactDOM.render(<App />, document.getElementById('root'))
}
