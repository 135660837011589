import {
  CategoryDataSets,
  MavenLinkRecordsType,
  MetaBaseParams,
  RecordTypes,
  DataSetTypes,
  IDataSet,
  MavenlinkDataSetStates,
} from '.'

export type BlueprintSort =
  | 'name'
  | 'created_at'
  | 'status'
  | 'service_user_id'
  | 'processed_at'
  | 'updated_at'
  | 'data_sets_count'
export type BlueprintCategories =
  | 'Finance'
  | 'Projects'
  | 'Users'
  | 'Time & Expense'
  | 'Tasks'
  | 'Others'
  | 'Home'

export enum EnumBlueprintCategories {
  PROJECTS = 'Projects',
  USERS = 'Users',
  TIMEXPENSES = 'Time & Expense',
  TASKS = 'Tasks',
  OTHERS = 'Others',
}

export interface Blueprint {
  id: number
  name: string
  account_id: number
  created_at: string
  updated_at: string
  processed_at?: string
  validated_at?: string
  validation_started_at?: string
  processing_started_at?: string
  user_name: string
  url: string
  data_sets: IDataSet[]
  data_sets_count: number
  status: MavenlinkDataSetStates
  categories: BlueprintCategories[]
}

export type BlueprintCategoryTabs = {
  [k in Exclude<BlueprintCategories, 'Home'>]?: CategoryDataSets
}

export interface IBlueprintPayloadRequest {
  name: string
  data_sets_attributes: MavenLinkRecordsType[]
}

export interface BlueprintBaseRequestParams {
  options: MavenlinkObject[]
  name?: string
}

export interface BlueprintsResponse {
  meta: MetaBaseParams
  blueprints: Blueprint[]
}

export interface BlueprintTabs extends BlueprintCategoryTabs {
  Home: IHomeSet
}

export interface IHomeSet {
  name?: string
  id?: string
}

export interface MavenlinkObject {
  name: RecordTypes
  display_name: Exclude<DataSetTypes, 'Home'>
  categories: BlueprintCategories[]
  // I get more props but i only need these atm.
}

export interface MavenLinkObjectsResponse {
  categories: BlueprintCategories[]
  meta: MetaBaseParams
  mavenlink_objects: MavenlinkObject[]
}
