import React, { useCallback, useState, memo, useMemo, useEffect } from 'react'
import { IDataSet, MavenlinkDataSetStates, IRecordError, EnumKrushStates } from '@interfaces'
import { Upload, MainButton, Table, Title, Tooltip, Modal } from '@components'
import classes from './index.module.scss'
import { useQuery, useMutation } from 'react-query'
import {
  EXPORT_CSV,
  GET_BLUEPRINT_URI,
  GET_CSV_TEMPLATE,
  GET_DATA_SET_RECORDS,
  RESET_DATASET_URI,
} from '@uris'
import { useStore, useBlueprintStatus } from '@hooks'
import { CircularProgress } from '@mui/material'
import { useAlert } from 'react-alert'
import { Box, IconButton } from '@mui/material'
import { Error as ErrorIcon, CheckCircle } from '@mui/icons-material'
import { List, ListItem } from '@mui/material'
import { shallow } from 'zustand/shallow'
import configs from '@configs'
import { removeErrorPreffix } from '@utils'

interface IProps {
  dataSet: IDataSet
  onlineStatus: MavenlinkDataSetStates
  progressStatus?: number | null
}
const refetchConfig = {
  refetchOnWindowFocus: false,
  enabled: false,
}
const DataSet = ({ dataSet, onlineStatus, progressStatus }: IProps) => {
  const {
    pages: {
      Blueprint: { Dataset: root },
    },
    configs: {
      cache: { TABLE_CONFIG_FETCH },
      colors: { DANGER, SUCCESS },
    },
  } = configs
  const [errorLogs, setErrorLogs] = useState<IRecordError[] | null>(null)
  const customizedAlert = useAlert()
  const { blueprint_id: blueprintId, display_name: displayName } = dataSet
  const datasetState = useMemo(() => {
    return root.prettifyOnlineStatus(onlineStatus)
  }, [onlineStatus])
  const [page, setPage] = useState<number>(1)
  const {
    isValidating,
    isValidated,
    isProcessing,
    isUploading,
    isUploaded,
    isCreated,
    isValidatedWithErrors,
    isProcessedWithErrors,
  } = useBlueprintStatus(onlineStatus)
  const [getRecords, getTemplate, exportCSV, getBlueprint, mutation] = useStore(
    (state) => [
      state.getRecords,
      state.getTemplate,
      state.exportCSV,
      state.getBlueprint,
      state.mutation,
    ],
    shallow
  )
  const { refetch: downloadTemplate } = useQuery(
    [GET_CSV_TEMPLATE(dataSet.mavenlink_record_type)],
    () => getTemplate(dataSet.mavenlink_record_type),
    refetchConfig
  )
  const { refetch: exportDataSet } = useQuery(
    [`${EXPORT_CSV(dataSet.id)}`],
    () => exportCSV(dataSet.id),
    refetchConfig
  )

  const { refetch: exportErrors } = useQuery(
    [`${EXPORT_CSV(dataSet.id, true)}`],
    () => exportCSV(dataSet.id, true),
    refetchConfig
  )

  const {
    data,
    isLoading,
    remove,
    refetch: fetchRecords,
  } = useQuery(
    [`${GET_DATA_SET_RECORDS(page, dataSet.id)}${onlineStatus}${dataSet.updated_at}`],
    () => getRecords(dataSet.id, page),
    {
      ...TABLE_CONFIG_FETCH,
      enabled: !isUploading && !isCreated,
    }
  )
  useQuery([GET_BLUEPRINT_URI(blueprintId)], () => getBlueprint(blueprintId), {
    enabled: isUploaded && !dataSet?.fields,
  })

  const { mutate: resetDataSet } = useMutation(mutation, {
    onSuccess: () => {
      customizedAlert.success(root.successReplace)
    },
    onError: (error: Error) => {
      customizedAlert.error(removeErrorPreffix(error.message))
    },
  })

  const handleReset = useCallback(() => {
    remove()
    setPage(1)
    resetDataSet({ uri: RESET_DATASET_URI(dataSet.id) })
  }, [dataSet.id])

  /* Needed when we switch to isUploading - isScreated  it means we are replacing we dont want to keep cache data, instead we need to refetch    */
  useEffect(() => {
    if (!isUploading && !isCreated) {
      fetchRecords()
    }
  }, [isUploading, isCreated, fetchRecords])

  /* Useful when we switch tabs we want to start on page 1 always.     */
  useEffect(() => {
    setPage(1)
  }, [dataSet.id])

  const parsedData = useMemo(() => {
    return data?.records.map((record) => {
      return {
        id: record.id,
        data: record.data.map((recordValue, recordIndex) => {
          const isFirstElement = recordIndex === 0
          return {
            value: recordValue,
            state:
              isFirstElement && record.error_logs && record.error_logs.length > 0 ? (
                <>
                  <Tooltip
                    key={`${recordValue}-${record.id}`}
                    title={root.tooltipErrorMessage}
                    arrow
                  >
                    <IconButton
                      key={`${recordValue}-${record.id}-button`}
                      sx={{
                        padding: 0,
                      }}
                      onClick={() => {
                        setErrorLogs(record.error_logs as IRecordError[])
                      }}
                    >
                      <ErrorIcon style={{ fill: DANGER }} />
                    </IconButton>
                  </Tooltip>
                  <Modal
                    key={`${recordValue}-${record.id}-modal`}
                    open={Boolean(errorLogs)}
                    onClose={() => setErrorLogs(null)}
                    title={root.errorLog}
                  >
                    <List>
                      {errorLogs?.map((log, index) => (
                        <ListItem key={`${log.id}${log.updated_at}`}>
                          {index + 1}.{`\t`}
                          {log.message}
                        </ListItem>
                      ))}
                    </List>
                  </Modal>
                </>
              ) : (
                onlineStatus &&
                isFirstElement &&
                onlineStatus === EnumKrushStates.UPLOADED && (
                  <IconButton disabled>
                    <CheckCircle style={{ fill: SUCCESS }} />
                  </IconButton>
                )
              ),
          }
        }),
      }
    })
  }, [data?.records, errorLogs, onlineStatus])

  const parsedHeaders = useMemo(() => {
    return dataSet.fields?.map((header) => ({ data: header }))
  }, [dataSet.fields, dataSet.id])

  return (
    <Box>
      {dataSet && (
        <Box className={classes.dataSetInfo}>
          <Title
            header={
              isValidating || isProcessing ? `${progressStatus}% - ${datasetState}` : datasetState
            }
          />
          {dataSet?.fields && !isCreated && (
            <>
              {(isValidated || isValidatedWithErrors || isUploaded) && (
                <MainButton
                  title={root.replace}
                  variant="outlined"
                  color="inherit"
                  className={classes['buttonStyle-1']}
                  onClick={handleReset}
                />
              )}
              <MainButton
                title={root.exportDataSet}
                variant="outlined"
                className={classes['buttonStyle-1']}
                onClick={() => exportDataSet()}
              />
              {(isProcessedWithErrors || isValidatedWithErrors) && (
                <MainButton
                  title={root.exportSetErrors}
                  variant="outlined"
                  color="secondary"
                  className={classes['buttonStyle-1']}
                  onClick={() => exportErrors()}
                />
              )}
            </>
          )}
        </Box>
      )}
      {data && parsedData && parsedHeaders && !isCreated && !isUploading ? (
        <Table
          headers={parsedHeaders}
          data={parsedData}
          page={page}
          setPage={setPage}
          totalPages={data.meta.total_pages}
          boldedHeaders
        />
      ) : isUploading || isLoading ? (
        <CircularProgress />
      ) : (
        <Box>
          <Upload key={`${blueprintId}${displayName}`} />
          <MainButton
            title={root.downloadTemplate}
            variant="outlined"
            color="primary"
            style={{ marginTop: '2em' }}
            onClick={() => downloadTemplate()}
          />
        </Box>
      )}
    </Box>
  )
}

export default memo(DataSet)
